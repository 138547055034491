import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import type { EventSpecificDays } from "../../util/types";
import CustomCheckbox from "../CustomCheckbox";

interface DaysCheckboxesProps {
  days: EventSpecificDays;
  handleChange: (key: string, value: boolean) => void;
}

const DaysCheckboxes: React.FC<DaysCheckboxesProps> = ({
  days,
  handleChange,
}) => {
  return (
    <Container>
      <Row>
        {Object.keys(days).map((key, index) => (
          <Col key={index} md="4">
            <CustomCheckbox
              value={days[key].value}
              handleChange={(value) => handleChange(key, value)}
              label={days[key].label}
              customClass="input-section-checkbox-container day-checkbox"
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

DaysCheckboxes.propTypes = {
  days: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DaysCheckboxes;
