import ActionType from "../actions/types";

interface FullPageLoadingIndicatorState {
  isOpen: boolean;
  message: string;
  opacity: number;
}

interface ShowFullPageLoadingIndicatorAction {
  type: ActionType.SHOW_FULL_PAGE_LOADING_INDICATOR;
  message: string;
}

interface HideFullPageLoadingIndicatorAction {
  type: ActionType.HIDE_FULL_PAGE_LOADING_INDICATOR;
}

interface DismissFullPageLoadingIndicatorAction {
  type: ActionType.DISMISS_FULL_PAGE_LOADING_INDICATOR;
}

type FullPageLoadingIndicatorAction =
  | ShowFullPageLoadingIndicatorAction
  | HideFullPageLoadingIndicatorAction
  | DismissFullPageLoadingIndicatorAction;

const initialState: FullPageLoadingIndicatorState = {
  isOpen: false,
  message: "",
  opacity: 0,
};

export default function fullPageLoadingIndicator(
  state: FullPageLoadingIndicatorState = { ...initialState },
  action: FullPageLoadingIndicatorAction
): FullPageLoadingIndicatorState {
  switch (action.type) {
    case ActionType.SHOW_FULL_PAGE_LOADING_INDICATOR: {
      return {
        isOpen: true,
        message: action.message,
        opacity: 1,
      };
    }
    case ActionType.HIDE_FULL_PAGE_LOADING_INDICATOR: {
      return {
        ...state,
        opacity: 0,
      };
    }
    case ActionType.DISMISS_FULL_PAGE_LOADING_INDICATOR: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
