import React from "react";
import { useDispatch } from "react-redux";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { dismissImageSlider } from "../../../actions/imageSlider";
import CustomModal from "../CustomModal";
import SliderImage from "./SliderImage";
import { MODAL_WIDTH } from "../../../util/constants";
import useAppSelector from "../../../hooks/useAppSelector";

const ImageSliderModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.imageSlider.isOpen);
  const images = useAppSelector((state) => state.imageSlider.images);
  const windowWidth = useWindowWidth(isOpen);

  const handleCancelClick = () => {
    dispatch(dismissImageSlider());
  };

  const width = (windowWidth < MODAL_WIDTH ? windowWidth : MODAL_WIDTH) - 10;
  const height = (width * 600) / 800;

  return (
    <CustomModal
      isOpen={isOpen}
      showFooter
      confirmLabel="Close"
      handleConfirm={handleCancelClick}
    >
      <div style={{ maxHeight: height }}>
        <Slide
          autoplay={false}
          transitionDuration={200}
          arrows={images.length > 1}
        >
          {images.map((image, index) => (
            <SliderImage key={index} src={image} />
          ))}
        </Slide>
      </div>
    </CustomModal>
  );
};

export default ImageSliderModal;
