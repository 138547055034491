import React from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import WillShowNoResultsWarning from "../../WillShowNoResultsWarning";

const WillShowNoResultsWarningContainer: React.FC = () => {
  const types = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.types
  );
  const groups = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.groups
  );
  const users = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.users
  );

  const hasGroups: boolean = (groups.length || false) as boolean;
  const hasUsers: boolean = (users.length || false) as boolean;

  return (
    <WillShowNoResultsWarning
      types={types}
      hasGroups={hasGroups}
      hasUsers={hasUsers}
    />
  );
};

export default WillShowNoResultsWarningContainer;
