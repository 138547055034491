import ActionType from "../actions/types";
import { ModalState } from "../util/types";

interface ConfirmModalState extends ModalState {
  action: (() => Promise<any>) | null;
}

interface ShowConfirmModalAction {
  type: ActionType.SHOW_CONFIRM_MODAL;
  action: () => Promise<any>;
}

interface DismissConfirmModalAction {
  type: ActionType.DISMISS_CONFIRM_MODAL;
}

type ConfirmModalAction = ShowConfirmModalAction | DismissConfirmModalAction;

const initialState: ConfirmModalState = {
  isOpen: false,
  action: null,
};

export default function confirmModal(
  state: ConfirmModalState = initialState,
  action: ConfirmModalAction
): ConfirmModalState {
  switch (action.type) {
    case ActionType.SHOW_CONFIRM_MODAL:
      return {
        isOpen: true,
        action: action.action,
      };
    case ActionType.DISMISS_CONFIRM_MODAL:
      return { ...initialState };
    default:
      return state;
  }
}
