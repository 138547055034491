import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";
import UnauthorizedPage from "../components/UnauthorizedPage";
import PrivateRoute from "./PrivateRoute";
import UnauthorizedRoute from "./UnauthorizedRoute";

const Login = React.lazy(() => import("../pages/Login"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const UserDashboard = React.lazy(() => import("../pages/UserDashboard"));
const ManagerDashboard = React.lazy(() => import("../pages/ManagerDashboard"));
const Group = React.lazy(() => import("../pages/Group"));
const Reports = React.lazy(() => import("../pages/Reports"));

class Routes extends Component {
  render() {
    return (
      <React.Suspense fallback={<UnauthorizedPage />}>
        <Switch>
          <UnauthorizedRoute path="/login" component={Login} />
          <UnauthorizedRoute
            path="/forgot-password"
            component={ForgotPassword}
          />
          <UnauthorizedRoute
            path="/reset-password/:userId/:timestamp/:hash"
            component={ResetPassword}
          />
          <PrivateRoute path="/user-dashboard/:id" component={UserDashboard} />
          <PrivateRoute
            path="/manager-dashboard"
            component={ManagerDashboard}
            roles={["admin", "manager"]}
          />
          <PrivateRoute path="/group/:id" component={Group} />
          <PrivateRoute path="/reports" component={Reports} />
          <Redirect to="/login" />
        </Switch>
      </React.Suspense>
    );
  }
}

export default Routes;
