import { AppThunkAction } from "../util/types";
import ActionType from "./types";

export const showFullPageLoadingIndicator = (message?: string) => ({
  type: ActionType.SHOW_FULL_PAGE_LOADING_INDICATOR,
  message,
});

const hideFullPageLoadingIndicator = () => ({
  type: ActionType.HIDE_FULL_PAGE_LOADING_INDICATOR,
});

export const dismissFullPageLoadingIndicator = () => ({
  type: ActionType.DISMISS_FULL_PAGE_LOADING_INDICATOR,
});

export const handleDismissFullPageLoadingIndicator =
  (): AppThunkAction => (dispatch) => {
    dispatch(hideFullPageLoadingIndicator());
    setTimeout(() => {
      dispatch(dismissFullPageLoadingIndicator());
    }, 1 * 1000);
  };
