import ActionType from "../actions/types";
import type {
  AlertRuleModalItem,
  AlertRuleModalSelected,
  EventLogs,
  KeyValue,
  ModalState,
  ValueLabel,
} from "../util/types";

interface EventAlertRuleModalState extends ModalState {
  alertRule: AlertRuleModalItem;
  selected: AlertRuleModalSelected;
  sampleRecords?: EventLogs;
  sampleRecordsLoading?: boolean;
}

interface ShowEmptyEventAlertRuleModalAction {
  type: ActionType.SHOW_EMPTY_EVENT_ALERT_RULE_MODAL;
}

interface ShowEventAlertRuleModalAction {
  type: ActionType.SHOW_EVENT_ALERT_RULE_MODAL;
  alertRule: AlertRuleModalItem;
  selected: AlertRuleModalSelected;
}

interface ShowEventAlertRuleModalSelectedItemAction {
  type: ActionType.SET_EVENT_ALERT_RULE_MODAL_SELECTED_ITEM;
  key: string;
  val: any;
}

interface ShowEventAlertRuleModalSampleRecordsLoadingAction {
  type: ActionType.SET_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS_LOADIG;
  sampleRecordsLoading: boolean;
}

interface ReceiveEventAlertRuleModalSampleRecordsLoadingAction {
  type: ActionType.RECEIVE_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS;
  sampleRecords: EventLogs;
}

interface DismissEventAlertRuleModalAction {
  type: ActionType.DISMISS_EVENT_ALERT_RULE_MODAL;
}

type EventAlertRuleModalAction =
  | ShowEmptyEventAlertRuleModalAction
  | ShowEventAlertRuleModalAction
  | ShowEventAlertRuleModalSelectedItemAction
  | ShowEventAlertRuleModalSampleRecordsLoadingAction
  | ReceiveEventAlertRuleModalSampleRecordsLoadingAction
  | DismissEventAlertRuleModalAction;

const DAYS: KeyValue<ValueLabel<boolean>> = {
  sat: {
    label: "Saturday",
    value: false,
  },
  sun: {
    label: "Sunday",
    value: false,
  },
  mon: {
    label: "Monday",
    value: false,
  },
  tue: {
    label: "Tuesday",
    value: false,
  },
  wed: {
    label: "Wednesday",
    value: false,
  },
  thu: {
    label: "Thursday",
    value: false,
  },
  fri: {
    label: "Friday",
    value: false,
  },
};

const initialState: EventAlertRuleModalState = {
  isOpen: false,
  alertRule: {
    time: {
      from: "00:00",
      to: "23:59",
    },
    days: { ...DAYS },
    types: [],
    groups: [],
    locations: [],
    users: [],
    channels: [],
  },
  selected: {
    types: [],
    properties: [],
    locations: [],
    users: [],
    groups: [],
  },
  sampleRecords: [],
  sampleRecordsLoading: false,
};

export default function eventAlertRuleModal(
  state: EventAlertRuleModalState = initialState,
  action: EventAlertRuleModalAction
): EventAlertRuleModalState {
  switch (action.type) {
    case ActionType.SHOW_EMPTY_EVENT_ALERT_RULE_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case ActionType.SHOW_EVENT_ALERT_RULE_MODAL:
      return {
        isOpen: true,
        alertRule: action.alertRule,
        selected: action.selected,
      };
    case ActionType.SET_EVENT_ALERT_RULE_MODAL_SELECTED_ITEM:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.key]: action.val,
        },
      };
    case ActionType.SET_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS_LOADIG:
      return {
        ...state,
        sampleRecordsLoading: action.sampleRecordsLoading,
      };
    case ActionType.RECEIVE_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS:
      return {
        ...state,
        sampleRecords: action.sampleRecords,
        sampleRecordsLoading: false,
      };
    case ActionType.DISMISS_EVENT_ALERT_RULE_MODAL:
      return {
        ...initialState,
        alertRule: {
          ...initialState.alertRule,
          days: {
            ...DAYS,
          },
        },
      };
    default:
      return state;
  }
}
