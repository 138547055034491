import { useEffect, useState } from "react";

export default function useImageSize(url: string) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    var img = new window.Image();
    img.onload = function () {
      setWidth((this as HTMLImageElement).width);
      setHeight((this as HTMLImageElement).height);
    };
    img.src = url;
  }, [url]);

  return [width, height];
}
