import { makeGET, makePOST } from "./methods";

export const getEventLogs = makePOST("eventlog/all");
export const getEventLogsCombined = makePOST("eventlog/combined/v2");
export const getEventLogsCSV = makePOST("eventlog/combined/csv");
export const getEventGroups = makeGET("managed/groups");
export const getEventUsers = makeGET("managed/users");
export const getEventTypes = makeGET("event-names/all");
export const getEventLocations = makeGET("managed/locations");
export const getEventGroupFilterData = makeGET("managed/all");
export const getEventProperties = makeGET("properties/all");

const getFace = makeGET("faces");
export const getFacesCluster = makeGET("face-clusters");

export const getFacesClusterByFaceId = async (faceId: string) => {
  try {
    const { face } = await getFace({ id: faceId });
    if (face && face.faces_person_id) {
      const { facesPerson } = await getFacesCluster({
        id: face.faces_person_id,
        params: { face_rows_limit: 32 },
      });
      return facesPerson;
    } else {
      return { error: "Couldn't get face persons" };
    }
  } catch (e) {
    console.log(e);
  }
};

export const assignFacesClusterToUser = (id: string) => {
  const path = `face-clusters/${id}/assign`;
  return makePOST(path);
};

export const unassignFacesCluster = (id: string) => {
  const path = `face-clusters/${id}/unassign`;
  return makePOST(path);
};

export const assignMultipleFacesClustersToUser = makePOST(
  "face-clusters/bulk/assign"
);
