import axios from "axios";

const AUTH_BASE = process.env.REACT_APP_API_AUTH_ENDPOINT;
const PRIVATE_BASE = process.env.REACT_APP_PRIVATE_API_ENDPOINT;
axios.defaults.withCredentials = true;

export const getAuthEndpoint = (api: string): string =>
  `${AUTH_BASE}${api}?_format=json`;

export const getPrivateEndpoint = (api: string): string =>
  `${PRIVATE_BASE}${api}`;

export default axios;
