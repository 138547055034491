import ActionType from "../actions/types";
import type {
  AttendanceReportTable,
  AttendanceReportTableRows,
} from "../util/types";

type AttendanceReportTableState = AttendanceReportTable;

interface ReceiveAttendanceReportRowsAction {
  type: ActionType.RECEIVE_ATTENDANCE_REPORT_ROWS;
  total: number;
  rows: AttendanceReportTableRows;
}

interface ReceiveAttendanceReportRowsError {
  type: ActionType.RECEIVE_ATTENDANCE_REPORT_ROWS_ERROR;
}

interface SetAttendanceReportTablePerPageValAction {
  type: ActionType.SET_ATTENDANCE_REPORT_TABLE_PER_PAGE_VAL;
  perPage: number;
}

interface SetAttendanceReportTablePageValAction {
  type: ActionType.SET_ATTENDANCE_REPORT_TABLE_PAGE_VAL;
  page: number;
}

interface SetAttendanceReportTableLoadingAction {
  type: ActionType.SET_ATTENDANCE_REPORT_TABLE_LOADING;
  loading: boolean;
}

interface ResetAttendanceReportTableAction {
  type: ActionType.RESET_ATTENDANCE_REPORT_TABLE;
  loading: boolean;
}

type AttendanceReportTableAction =
  | ReceiveAttendanceReportRowsAction
  | ReceiveAttendanceReportRowsError
  | SetAttendanceReportTablePerPageValAction
  | SetAttendanceReportTablePageValAction
  | SetAttendanceReportTableLoadingAction
  | ResetAttendanceReportTableAction;

const initState: AttendanceReportTableState = {
  total: 0,
  perPage: 10,
  page: 1,
  loading: false,
  rows: [],
};

export default function attendanceReportTable(
  state: AttendanceReportTableState = initState,
  action: AttendanceReportTableAction
): AttendanceReportTableState {
  switch (action.type) {
    case ActionType.RECEIVE_ATTENDANCE_REPORT_ROWS:
      return {
        ...state,
        total: action.total,
        rows: action.rows,
        loading: false,
      };
    case ActionType.RECEIVE_ATTENDANCE_REPORT_ROWS_ERROR:
      return {
        ...state,
        total: 0,
        rows: [],
        loading: false,
      };
    case ActionType.SET_ATTENDANCE_REPORT_TABLE_PER_PAGE_VAL:
      return {
        ...state,
        perPage: action.perPage,
      };
    case ActionType.SET_ATTENDANCE_REPORT_TABLE_PAGE_VAL:
      return {
        ...state,
        page: action.page,
      };
    case ActionType.SET_ATTENDANCE_REPORT_TABLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionType.RESET_ATTENDANCE_REPORT_TABLE:
      return { ...initState };
    default:
      return state;
  }
}
