import ActionType from "../actions/types";
import { ModalState } from "../util/types";

interface AddUserToGroupModalState extends ModalState {
  groupId: string | null;
  email: string | null;
}

const initialState: AddUserToGroupModalState = {
  isOpen: false,
  groupId: null,
  email: null,
};

interface ShowAddUserToGroupModalAction {
  type: ActionType.SHOW_ADD_USER_TO_GROUP_MODAL;
  groupId: string;
  email: string;
}

interface DismissAddUserToGroupModalAction {
  type: ActionType.DISMISS_ADD_USER_TO_GROUP_MODAL;
}

type AddUserToGroupModalAction =
  | ShowAddUserToGroupModalAction
  | DismissAddUserToGroupModalAction;

export default function addUserToGroupModal(
  state: AddUserToGroupModalState = { ...initialState },
  action: AddUserToGroupModalAction
): AddUserToGroupModalState {
  switch (action.type) {
    case ActionType.SHOW_ADD_USER_TO_GROUP_MODAL:
      return {
        isOpen: true,
        groupId: action.groupId,
        email: action.email,
      };
    case ActionType.DISMISS_ADD_USER_TO_GROUP_MODAL:
      return {
        isOpen: false,
        groupId: null,
        email: null,
      };
    default:
      return state;
  }
}
