import ActionType from "../actions/types";
import { ValueLabel } from "../util/types";

interface AttendanceReportSelectedFiltersState {
  startDate: Date;
  endDate: Date;
  groups: ValueLabel<string>[];
  users: ValueLabel<string>[];
}

interface SetAttendanceReportStartDateAction {
  type: ActionType.SET_ATTENDANCE_REPORT_START_DATE;
  value: Date;
}

interface SetAttendanceReportEndDateAction {
  type: ActionType.SET_ATTENDANCE_REPORT_END_DATE;
  value: Date;
}

interface SetAttendanceReportGroupsAction {
  type: ActionType.SET_ATTENDANCE_REPORT_GROUPS;
  value: ValueLabel<string>[];
}

interface SetAttendanceReportUsersAction {
  type: ActionType.SET_ATTENDANCE_REPORT_USERS;
  value: ValueLabel<string>[];
}

interface ResetAttendanceReportFiltersAction {
  type: ActionType.RESET_SELECTED_ATTENDANCE_REPORT_FILTERS;
}

type AttendanceReportSelectedFiltersAction =
  | SetAttendanceReportStartDateAction
  | SetAttendanceReportEndDateAction
  | SetAttendanceReportGroupsAction
  | SetAttendanceReportUsersAction
  | ResetAttendanceReportFiltersAction;

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const initState: AttendanceReportSelectedFiltersState = {
  startDate: lastMonth,
  endDate: new Date(),
  groups: [],
  users: [],
};

export default function attendanceReportSelectedFilters(
  state: AttendanceReportSelectedFiltersState = initState,
  action: AttendanceReportSelectedFiltersAction
): AttendanceReportSelectedFiltersState {
  switch (action.type) {
    case ActionType.SET_ATTENDANCE_REPORT_START_DATE: {
      return {
        ...state,
        startDate: action.value,
      };
    }
    case ActionType.SET_ATTENDANCE_REPORT_END_DATE: {
      return {
        ...state,
        endDate: action.value,
      };
    }
    case ActionType.SET_ATTENDANCE_REPORT_GROUPS: {
      return {
        ...state,
        groups: action.value,
      };
    }
    case ActionType.SET_ATTENDANCE_REPORT_USERS: {
      return {
        ...state,
        users: action.value,
      };
    }
    case ActionType.RESET_SELECTED_ATTENDANCE_REPORT_FILTERS: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}
