function getMonthName(date: Date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[date.getMonth()];
}

export function getTime(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const hoursStr = hours < 10 ? "0" + hours : hours;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return `${hoursStr}:${minutesStr} ${ampm}`;
}

export function getTime24(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const hoursStr = hours < 10 ? "0" + hours : hours;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return hoursStr + ":" + minutesStr;
}

export function getDateYYYYMMDD(date: Date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  const monthStr = month < 10 ? "0" + month : month;
  const dayStr = day < 10 ? "0" + day : day;
  return `${year}-${monthStr}-${dayStr}`;
}

/**
 *
 * @param {Number} ms
 * ex: October 10, 2020
 */
export function formatDateWithoutTime(ms: number) {
  const date = new Date(Number(ms));
  return getMonthName(date) + " " + date.getDate() + ", " + date.getFullYear();
}

export function changeTimezone(date: Date, ianatz: string) {
  var invdate = new Date(
    date.toLocaleString("en-US", {
      timeZone: ianatz,
    })
  );
  var diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() - diff);
}

/**
 *
 * @param {Number} ms
 * ex: 10:30am October 10, 2020
 */
export default function formatDate(ms: number, timezone?: string) {
  let date = new Date(Number(ms));
  if (timezone) {
    date = changeTimezone(date, timezone);
  }
  return (
    getTime(date) +
    " " +
    getMonthName(date) +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
}
