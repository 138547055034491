import ActionType from "../actions/types";
import { ModalState } from "../util/types";

interface SelectedUnknownEventFacesModalState extends ModalState {}

interface ShowSelectedUnknownEventFacesModalAction {
  type: ActionType.SHOW_SELECTED_UNKNOWN_EVET_FACES_MODAL;
}

interface DismissSelectedUnknownEventFacesModalAction {
  type: ActionType.DISMISS_SELECTED_UNKNOWN_EVET_FACES_MODAL;
}

type SelectedUnknownEventFacesModalAction =
  | ShowSelectedUnknownEventFacesModalAction
  | DismissSelectedUnknownEventFacesModalAction;

const initialState: SelectedUnknownEventFacesModalState = {
  isOpen: false,
};

export default function selectedUnknownEventFacesModal(
  state: SelectedUnknownEventFacesModalState = initialState,
  action: SelectedUnknownEventFacesModalAction
): SelectedUnknownEventFacesModalState {
  switch (action.type) {
    case ActionType.SHOW_SELECTED_UNKNOWN_EVET_FACES_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case ActionType.DISMISS_SELECTED_UNKNOWN_EVET_FACES_MODAL:
      return { ...initialState };
    default:
      return state;
  }
}
