import ActionType from "../actions/types";
import { Groups } from "../util/types";

type GroupsState = Groups;

interface GetGroupsAction {
  type: ActionType.GET_GROUPS;
  groups: Groups;
}

interface ClearGroupsAction {
  type: ActionType.CLEAR_GROUPS;
}

interface RemoveSubgroupAction {
  type: ActionType.REMOVE_SUBGROUP;
  subgroupId: string;
}

interface UpdateGroupSubgroupsAction {
  type: ActionType.UPDATE_GROUP_SUBGROUPS;
  subgroups: string[];
  groupId: string;
}

type GroupsAction =
  | GetGroupsAction
  | ClearGroupsAction
  | RemoveSubgroupAction
  | UpdateGroupSubgroupsAction;

export default function groups(
  state: GroupsState = {},
  action: GroupsAction
): GroupsState {
  switch (action.type) {
    case ActionType.GET_GROUPS:
      return { ...state, ...action.groups };
    case ActionType.CLEAR_GROUPS:
      return {};
    case ActionType.REMOVE_SUBGROUP:
      return {
        ...state,
        [action.subgroupId]: {
          ...state[action.subgroupId],
          relations: {
            ...state[action.subgroupId].relations,
            parentGroup: {},
          },
        },
      };
    case ActionType.UPDATE_GROUP_SUBGROUPS:
      const groups: Groups = {};
      action.subgroups.forEach((subgroupId) => {
        groups[subgroupId] = { ...state[subgroupId] };
      });
      return {
        ...state,
        [action.groupId]: {
          ...state[action.groupId],
          relations: {
            ...state[action.groupId].relations,
            groups,
          },
        },
      };
    default:
      return state;
  }
}
