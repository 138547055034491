import ActionType from "./types";

export const selectUnkownEventFace = ({
  facesClusterImg,
  facesClusterId,
}: {
  facesClusterImg: string;
  facesClusterId: string;
}) => ({
  type: ActionType.SELECT_UNKNOWN_EVENT_FACE,
  facesClusterImg,
  facesClusterId,
});

export const deselectUnkownEventFace = (facesClusterId: string) => ({
  type: ActionType.DESELECT_UNKNOWN_EVENT_FACE,
  facesClusterId,
});

export const resetUnkownEventFaces = () => ({
  type: ActionType.RESET_UNKNOWN_EVENT_FACES,
});
