import React from "react";
import ConfirmModal from "./ConfirmModal";
import ImageSlider from "./ImageSliderModal";
import VideoModal from "./VideoModal";
import FacesClusterModal from "./FacesClusterModal";
import EventAlertRuleModal from "./EventAlertRuleModal";
import SelectedUnknownEventFacesModal from "./SelectedUnknownEventFacesModal";
import AddUserToGroupModal from "./AddUserToGroupModal";

const Modals: React.FC = () => {
  return (
    <React.Fragment>
      <ConfirmModal />
      <ImageSlider />
      <VideoModal />
      <FacesClusterModal />
      <EventAlertRuleModal />
      <SelectedUnknownEventFacesModal />
      <AddUserToGroupModal />
    </React.Fragment>
  );
};

export default Modals;
