import ActionType from "./types";

export const showVideoModal = ({
  url,
  name,
}: {
  url: string;
  name: string;
}) => ({
  type: ActionType.SHOW_VIDEO_MODAL,
  url,
  name,
});

export const dismissVideoModal = () => ({
  type: ActionType.DISMISS_VIDEO_MODAL,
});
