import React from "react";
import { css } from "@emotion/core";
import Loader from "react-spinners/SyncLoader";
import { Card } from "@material-ui/core";
import useAppSelector from "../../hooks/useAppSelector";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4e73df;
`;

const FullPageLoadingIndicator: React.FC = () => {
  const isOpen = useAppSelector(
    (state) => state.fullPageLoadingIndicator.isOpen
  );
  const opacity = useAppSelector(
    (state) => state.fullPageLoadingIndicator.opacity
  );
  const message = useAppSelector(
    (state) => state.fullPageLoadingIndicator.message
  );

  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,.3)",
        zIndex: 10000,
        display: isOpen ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        transition: "1s",
        opacity,
      }}
    >
      <Card
        style={{
          minWidth: 200,
          height: 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          padding: 20,
        }}
      >
        <h3 style={{ textTransform: "capitalize" }}>
          {message || "Loading..."}
        </h3>
        <div className="sweet-loading" style={{ padding: "20px 0" }}>
          <Loader css={override} size={30} color={"#4e73df"} loading={isOpen} />
        </div>
      </Card>
    </div>
  );
};

export default FullPageLoadingIndicator;
