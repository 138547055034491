import { toast } from "react-toastify";
import { getEventLogsCombined, getEventLogsCSV } from "../api/events";
import {
  showFullPageLoadingIndicator,
  handleDismissFullPageLoadingIndicator,
} from "./fullPageLoadingIndicator";
import formatDate, { getTime24 } from "../util/formatDate";
import downloadFile from "../util/downloadFile";
import ActionType from "./types";
import {
  AppThunkAction,
  EventLog,
  EventLogResponseItem,
  EventLogs,
  EventLogsAPIResponse,
  EventProperty,
  EventSpecificDays,
  OrderByOption,
} from "../util/types";

const setEventTableOrder = ({
  orderBy,
  orderDirection,
}: {
  orderBy: string;
  orderDirection: string;
}) => ({
  type: ActionType.SET_EVENT_TABLE_ORDER,
  orderBy,
  orderDirection,
});

const setEventTablePerPageVal = (perPage: number) => ({
  type: ActionType.SET_EVENT_TABLE_PER_PAGE_VAL,
  perPage,
});

const setEventTablePageVal = (page: number) => ({
  type: ActionType.SET_EVENT_TABLE_PAGE_VAL,
  page,
});

const setEventTableLoading = (loading: boolean) => ({
  type: ActionType.SET_EVENT_TABLE_LOADING,
  loading,
});

const receiveEventLogs = ({
  total,
  events,
}: {
  total: number;
  events: EventLogs;
}) => ({
  type: ActionType.RECEIVE_EVENT_LOGS,
  total,
  events,
});

export const setEventTableFilterFixedUserId = (userId: string) => ({
  type: ActionType.SET_EVENT_TABLE_FILTER_FIXED_USER_ID,
  userId,
});

export const resetEventTableFilterFixedUserId = () => ({
  type: ActionType.RESET_EVENT_TABLE_FILTER_FIXED_USER_ID,
});

export const setEventTableFilterFixedGroupId = (groupId: string) => ({
  type: ActionType.SET_EVENT_TABLE_FILTER_FIXED_GROUP_ID,
  groupId,
});

export const resetEventTableFilterFixedGroupId = () => ({
  type: ActionType.RESET_EVENT_TABLE_FILTER_FIXED_GROUP_ID,
});

export const clearEventLogs = () => ({
  type: ActionType.RESET_EVENT_LOGS_TABLE,
});

export const resetSelectedEventFilters = () => ({
  type: ActionType.RESET_SELECTED_EVENT_FILTERS,
});

export const handleChangePage =
  (page: number): AppThunkAction =>
  (dispatch) => {
    dispatch(setEventTablePageVal(page));
    dispatch(handleReceiveEventLogs());
  };

export const handleChangePerPage =
  (perPage: number): AppThunkAction =>
  (dispatch) => {
    dispatch(setEventTablePerPageVal(perPage));
    dispatch(handleReceiveEventLogs());
  };

export const handleSetTableOrder =
  (option: OrderByOption): AppThunkAction =>
  (dispatch, getState) => {
    const { orderBy, orderDirection } = getState().eventLogsTable;
    if (option === orderBy) {
      dispatch(
        setEventTableOrder({
          orderBy,
          orderDirection: orderDirection === "asc" ? "desc" : "asc",
        })
      );
    } else {
      dispatch(
        setEventTableOrder({
          orderBy,
          orderDirection: "asc",
        })
      );
    }
    dispatch(handleReceiveEventLogs());
  };

export const receiveEventLogsError = () => ({
  type: ActionType.RECEIVE_EVENT_LOGS_ERROR,
});

/* To always make sure handleReceiveEventLogs uses the latest API response */
let handleReceiveEventLogsCallId;

export const handleReceiveEventLogs =
  (): AppThunkAction => async (dispatch, getState) => {
    const _callId = Date.now();
    handleReceiveEventLogsCallId = _callId;
    try {
      dispatch(setEventTableLoading(true));
      const {
        startDate,
        endDate,
        specificDays,
        specificTimeFrom,
        specificTimeTo,
        types,
        properties,
        locations,
        groups,
        users,
        eventsNotAssociatedWithUserFlag,
        fixedUserId,
        fixedGroupId,
      } = { ...getState().eventSelectedFilters };
      const { perPage, page, orderBy, orderDirection } =
        getState().eventLogsTable;
      let _locationIdsByProperties: number[] = [];
      if (properties.length) {
        const { eventFilterData } = getState();
        _locationIdsByProperties = eventFilterData.locations
          .filter((locationData) =>
            properties.find(
              (property) => property.label === locationData.label.split(":")[0]
            )
          )
          .map(({ value }) => value);
      }
      const now = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      const body = {
        groups: fixedGroupId
          ? [fixedGroupId]
          : groups.map(({ value }) => value),
        event_types: types.map(({ value }) => value),
        locations: [..._locationIdsByProperties].concat(
          locations.map(({ value }) => value)
        ),
        users: fixedUserId ? [fixedUserId] : users.map(({ value }) => value),
        not_assigned_users_only: eventsNotAssociatedWithUserFlag,
        date_unix_from: String(((startDate || lastMonth) as number) / 1000),
        date_unix_to: String(((endDate || now) as number) / 1000),
        days: mapDaysForRequestBody(specificDays),
        from_time: specificTimeFrom ? getTime24(specificTimeFrom) : "00:00:00",
        to_time: specificTimeTo ? getTime24(specificTimeTo) : "23:59:59",
        rows_from: (page - 1) * perPage,
        rows_limit: perPage,
        order: orderBy,
        order_direction: orderDirection,
      };
      const { combined_events }: { combined_events: EventLogsAPIResponse } =
        await getEventLogsCombined(body);
      /* 
      Comparing call Ids
      If they don't match then this isn't the latest result
      and shouldn't be used
    */
      if (handleReceiveEventLogsCallId !== _callId) return;
      if (combined_events && combined_events.events) {
        const currentUser = { ...getState().currentUser };
        const { timezone } = currentUser;
        const events: EventLogs = Object.values(combined_events.events).map(
          (event: EventLogResponseItem): EventLog => ({
            id: event.id,
            type: event.event_type,
            timestamp: formatDate(event.date * 1000, timezone),
            fullLocation: `${event.property_name}: ${event.location_name}`,
            userId: event.user_id || event.cluster_user_id,
            snapshots: event.snapshots || [],
            unixDate: Number(event.date),
            videoName: event.video_name,
            faceId: event.face_id,
            facesClusterId: event.face_cluster_id,
          })
        );
        dispatch(
          receiveEventLogs({
            total: combined_events.rows_total,
            events: events.sort((a, b) =>
              orderDirection === "desc"
                ? b.unixDate - a.unixDate
                : a.unixDate - b.unixDate
            ),
          })
        );
      } else {
        dispatch(
          receiveEventLogs({
            total: 0,
            events: [],
          })
        );
      }
    } catch (e) {
      console.log(e);
      if (handleReceiveEventLogsCallId !== _callId) return;
      dispatch(receiveEventLogsError());
    }
  };

export const handleEventFilterChange =
  ({ type, value }: { type: string; value: any }): AppThunkAction =>
  (dispatch) => {
    dispatch({ type, value });
    dispatch(setEventTablePageVal(1));
    dispatch(handleReceiveEventLogs());
  };

export const handleEventFiltersReset = (): AppThunkAction => (dispatch) => {
  dispatch(resetSelectedEventFilters());
  dispatch(handleReceiveEventLogs());
};

export const handleEventDownloadCSV =
  (): AppThunkAction => async (dispatch, getState) => {
    try {
      dispatch(showFullPageLoadingIndicator("Preparing your file..."));
      const {
        startDate,
        endDate,
        specificDays,
        specificTimeFrom,
        specificTimeTo,
        types,
        properties,
        locations,
        groups,
        users,
        eventsNotAssociatedWithUserFlag,
        fixedUserId,
        fixedGroupId,
      } = getState().eventSelectedFilters;
      const { perPage, page, orderBy, orderDirection } =
        getState().eventLogsTable;
      let _locationIdsByProperties: number[] = [];
      if (properties.length) {
        const { eventFilterData } = getState();
        _locationIdsByProperties = eventFilterData.locations
          .filter((locationData) =>
            properties.find(
              (property: EventProperty) =>
                property.label === locationData.label.split(":")[0]
            )
          )
          .map(({ value }) => value);
      }
      const body = {
        groups: fixedGroupId
          ? [fixedGroupId]
          : groups.map(({ value }) => value),
        event_types: types.map(({ value }) => value),
        locations: [..._locationIdsByProperties].concat(
          locations.map(({ value }) => value)
        ),
        users: fixedUserId ? [fixedUserId] : users.map(({ value }) => value),
        not_assigned_users_only: eventsNotAssociatedWithUserFlag,
        date_unix_from: String((startDate || 0) / 1000),
        date_unix_to: String((endDate || 0) / 1000),
        days: mapDaysForRequestBody(specificDays),
        from_time: specificTimeFrom ? getTime24(specificTimeFrom) : "00:00:00",
        to_time: specificTimeTo ? getTime24(specificTimeTo) : "23:59:59",
        rows_from: (page - 1) * perPage,
        rows_limit: perPage,
        order: orderBy,
        order_direction: orderDirection,
      };
      const { combined_events } = await getEventLogsCSV(body);
      dispatch(handleDismissFullPageLoadingIndicator());
      if (combined_events && combined_events.csv_url) {
        const csvURL =
          process.env.REACT_APP_API_CSV_BASE_LINK + combined_events.csv_url;
        downloadFile(csvURL);
      }
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      dispatch(handleDismissFullPageLoadingIndicator());
      console.log(e);
    }
  };

function mapDaysForRequestBody(days: EventSpecificDays) {
  return Object.values(days)
    .filter(({ value }) => value)
    .map(({ label }) => label);
}
