import { toast } from "react-toastify";
import { getAlertRules, deleteAlertRule } from "../api/alertRules";
import type {
  AlertRules,
  AlertRulesResponse,
  AppThunkAction,
} from "../util/types";
import ActionType from "./types";

const receiveEventRules = (alertRules: AlertRules) => ({
  type: ActionType.RECEIVE_ALERT_RULES,
  alertRules,
});

export const handleReceiveEventRules =
  (): AppThunkAction => async (dispatch) => {
    try {
      const { alert_rules }: { alert_rules: AlertRulesResponse } =
        await getAlertRules();
      const alertRules = Object.values(alert_rules).map((alertRule) => ({
        id: alertRule.id[0].value,
        types: alertRule.event_types.map((eventType) => eventType.value),
        users: alertRule.users.map((user) => Number(user.target_id)),
        groups: alertRule.groups.map((group) => Number(group.target_id)),
        time: {
          from: alertRule.from_time_string[0]
            ? alertRule.from_time_string[0].value
            : "",
          to: alertRule.to_time_string[0]
            ? alertRule.to_time_string[0].value
            : "",
        },
        locations: alertRule.locations.map((location) =>
          Number(location.target_id)
        ),
        channels: alertRule.channels.map((channel) => channel.value),
        days: alertRule.days.map((day) => day.value),
        properties: alertRule.properties.map((property) =>
          Number(property.target_id)
        ),
      }));
      dispatch(receiveEventRules(alertRules));
    } catch (e) {
      console.log(e);
    }
  };

export const handleDeleteEventRule =
  (id: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const { request_status, message } = await deleteAlertRule(id);
      if (request_status) {
        dispatch(handleReceiveEventRules());
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log(e);
    }
  };
