import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

interface FacesToggleButtonProps {
  label: string;
  onClick: () => void;
}

const FacesToggleButton: React.FC<FacesToggleButtonProps> = ({
  label,
  onClick,
}) => {
  return (
    <Row>
      <Col md="12" style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ padding: 5, paddingTop: 16 }}>
          <span className="clickable-span" onClick={onClick}>
            {label}
          </span>
        </div>
      </Col>
    </Row>
  );
};

FacesToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FacesToggleButton;
