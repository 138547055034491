import React from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import { compareIds } from "../../../util/compare";

interface UserNameProps {
  id: string;
}

const UserName: React.FC<UserNameProps> = ({ id }) => {
  const users = useAppSelector((state) => state.eventFilterData.users);
  const user = users.find((u) => compareIds(u.value, id));
  return user ? <span>{user.label}</span> : null;
};

export default UserName;
