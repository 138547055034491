import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { handleCheckForUser } from "./actions/currentUser";
import Routes from "./routes";
import FullPageLoadingIndicator from "./components/FullPageLoadingIndicator";
import Modals from "./components/modals/Modals";
import useAppSelector from "./hooks/useAppSelector";

const App = () => {
  const dispatch = useDispatch();
  const isLoaded = useAppSelector((state) => state.common.isLoaded);
  const currentUser = useAppSelector((state) => state.currentUser);

  React.useEffect(() => {
    dispatch(handleCheckForUser());
  }, [dispatch]);

  return isLoaded ? (
    <BrowserRouter>
      <FullPageLoadingIndicator />
      <Routes />
      {currentUser && <Modals />}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  ) : (
    <React.Fragment />
  );
};

export default App;
