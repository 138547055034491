import { toast } from "react-toastify";
import { assignMultipleFacesClustersToUser } from "../api/events";
import { handleReceiveEventLogs } from "./eventLogs";
import { resetUnkownEventFaces } from "./selectedUnknownEventFaces";
import {
  showFullPageLoadingIndicator,
  dismissFullPageLoadingIndicator,
} from "./fullPageLoadingIndicator";
import ActionType from "./types";
import { AppThunkAction, ID, KeyValue } from "../util/types";

export const showSelectedUnknownEventFacesModal = () => ({
  type: ActionType.SHOW_SELECTED_UNKNOWN_EVET_FACES_MODAL,
});

export const dismissSelectedUnknownEventFacesModal = () => ({
  type: ActionType.DISMISS_SELECTED_UNKNOWN_EVET_FACES_MODAL,
});

export const handleAssignSelectedUnknownEventFacesToUser =
  (newUserId: ID): AppThunkAction =>
  async (dispatch, getState) => {
    dispatch(showFullPageLoadingIndicator());
    try {
      const { selectedUnknownEventFaces } = getState();
      const data: KeyValue<ID> = {};
      Object.keys(selectedUnknownEventFaces).forEach((facesClusterId) => {
        data[facesClusterId] = newUserId;
      });
      await assignMultipleFacesClustersToUser({
        data,
      });
      toast.success("Assigned successfully");
      dispatch(handleReceiveEventLogs());
      dispatch(dismissSelectedUnknownEventFacesModal());
      dispatch(resetUnkownEventFaces());
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
    dispatch(dismissFullPageLoadingIndicator());
  };
