import { getCurrentUser } from "../api/currentUser";
import { getCookie } from "../util/cookies";
import { TOKEN_KEY } from "../util/constants";
import { setAppIsLoaded } from "./common";
import type { AppThunkAction, CurrentUser } from "../util/types";
import ActionType from "./types";

export const setCurrentUser = (currentUser: CurrentUser) => ({
  type: ActionType.SET_CURRENT_USER,
  currentUser,
});

export const unsetCurrentUser = () => ({
  type: ActionType.UNSET_CURRENT_USER,
});

export const updateCurrentUserAvatar = (avatar: string) => ({
  type: ActionType.UPDATE_CURRENT_USER_AVATAR,
  avatar,
});

export const handleCheckForUser = (): AppThunkAction => (dispatch) => {
  const token = getCookie(TOKEN_KEY);
  if (token) dispatch(handleGetCurrentUser());
  else {
    dispatch(setAppIsLoaded());
  }
};

export const handleGetCurrentUser = (): AppThunkAction => async (dispatch) => {
  try {
    const currentUser = await getCurrentUser();
    dispatch(setCurrentUser(currentUser));
  } catch (e) {
    console.log(e);
  }
  dispatch(setAppIsLoaded());
};
