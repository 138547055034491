import { combineReducers } from "redux";
import common from "./common";
import currentUser from "./currentUser";
import groups from "./groups";
import user from "./user";
import breadcrumb from "./breadcrumb";
import confirmModal from "./confirmModal";
import imageSlider from "./imageSlider";
import tasks from "./tasks";
import eventLogsTable from "./eventLogs";
import eventFilterData from "./eventFilterData";
import eventSelectedFilters from "./eventSelectedFilters";
import videoModal from "./videoModal";
import facesClusterModal from "./facesClusterModal";
import selectedUnknownEventFaces from "./selectedUnknownEventFaces";
import selectedUnknownEventFacesModal from "./selectedUnknownEventFacesModal";
import eventAlertRuleModal from "./eventAlertRuleModal";
import fullPageLoadingIndicator from "./fullPageLoadingIndicator";
import alertRules from "./alertRules";
import attendanceReportSelectedFilters from "./attendanceReportSelectedFilters";
import attendanceReportTable from "./attendanceReportTable";
import dailyArrivalAndDepartureReportSelectedFilters from "./dailyArrivalAndDepartureReportSelectedFilters";
import dailyArrivalAndDepartureReportTable from "./dailyArrivalAndDepartureTable";
import locks from "./locks";
import addUserToGroupModal from "./addUserToGroupModal";
// import { RootState } from "../store";

export interface RootState {
  currentUser: typeof currentUser;
  eventFilterData: typeof eventFilterData;
  eventLogsTable: typeof eventLogsTable;
  eventSelectedFilters: typeof eventSelectedFilters;
  groups: typeof groups;
  locks: typeof locks;
  user: typeof user;
  addUserToGroupModal: typeof addUserToGroupModal;
  tasks: typeof tasks;
  alertRules: typeof alertRules;
  facesClusterModal: typeof facesClusterModal;
  fullPageLoadingIndicator: typeof fullPageLoadingIndicator;
  selectedUnknownEventFaces: typeof selectedUnknownEventFaces;
  selectedUnknownEventFacesModal: typeof selectedUnknownEventFacesModal;
  attendanceReportSelectedFilters: typeof attendanceReportSelectedFilters;
  attendanceReportTable: typeof attendanceReportTable;
  imageSlider: typeof imageSlider;
  videoModal: typeof videoModal;
  confirmModal: typeof confirmModal;
  breadcrumb: typeof breadcrumb;
  eventAlertRuleModal: typeof eventAlertRuleModal;
  dailyArrivalAndDepartureReportTable: typeof dailyArrivalAndDepartureReportTable;
  dailyArrivalAndDepartureReportSelectedFilters: typeof dailyArrivalAndDepartureReportSelectedFilters;
  common: typeof common;
  [key: string]: any;
}

const reducers = combineReducers<RootState>({
  common,
  currentUser,
  groups,
  user,
  breadcrumb,
  confirmModal,
  imageSlider,
  tasks,
  eventLogsTable,
  eventFilterData,
  eventSelectedFilters,
  videoModal,
  facesClusterModal,
  selectedUnknownEventFaces,
  selectedUnknownEventFacesModal,
  eventAlertRuleModal,
  fullPageLoadingIndicator,
  alertRules,
  attendanceReportSelectedFilters,
  attendanceReportTable,
  dailyArrivalAndDepartureReportSelectedFilters,
  dailyArrivalAndDepartureReportTable,
  locks,
  addUserToGroupModal,
});

export default reducers;
