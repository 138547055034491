import ActionType from "./types";

export const showImageSlider = (images: string[]) => ({
  type: ActionType.SHOW_IMAGE_SLIDER,
  images,
});

export const dismissImageSlider = () => ({
  type: ActionType.DISMISS_IMAGE_SLIDER,
});
