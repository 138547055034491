import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAppSelector from "../hooks/useAppSelector";
import useIsAdmin from "../hooks/useIsAdmin";
import useIsManager from "../hooks/useIsManager";

interface PrivateRouteProps {
  component: React.FC<any>;
  roles?: string[];
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const currentUser = useAppSelector((state) => state.currentUser);
  const isAdmin = useIsAdmin();
  const isManager = useIsManager();

  const hasRole = () => {
    if (!roles) return false;
    return (
      (roles.includes("admin") && isAdmin) ||
      (roles.includes("manager") && isManager)
    );
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          if (!roles || hasRole()) {
            return <Component {...props} />;
          } else {
            return <Redirect to={`/user-dashboard/${currentUser.id}`} />;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired),
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
