import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import type { ThunkAction, ThunkDispatch } from "redux-thunk";
import type { RootState } from "../store";

export interface UserRelations {
  doors?: KeyValue<string>;
  memberOf: {
    [key: string]: string;
  };
  managerOf: {
    [key: string]: string;
  };
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  timezone: string;
  accessCode: string;
  rfid: string;
  schedule: boolean;
  enabledAccessToLocks: string;
  avatar: string;
  roles: string[];
  phoneNumbers: string[];
  facesPersons: any[];
  faceClusters: { [key: string]: any };
  relations: UserRelations;
  name?: string;
}

export type CurrentUser = User;

export interface GroupRelations {
  managers: {
    [key: string]: string;
  };
  users: {
    [key: string]: string;
  };
  groups: {
    [key: string]: Group;
  };
  parentGroup?: any;
}

export interface Group {
  id: ID;
  name: string;
  suite?: string;
  phoneNumbers: string[];
  relations: GroupRelations;
}

export interface Groups {
  [key: string]: Group;
}

export interface UserGroup {
  id: ID;
  name: string;
  usersCount: number;
  managers: string[];
}

export interface ValueLabel<T> {
  value: T;
  label: string;
}

export interface KeyValue<T> {
  [key: string]: T;
}

export type EventType = ValueLabel<string>;

export type EventTypes = EventType[];

export type EventLocation = ValueLabel<number>;

export type EventLocations = EventLocation[];

export type EventProperty = ValueLabel<number>;

export type EventProperties = EventProperty[];

export type EventGroup = ValueLabel<number>;

export type EventGroups = EventGroup[];

export type EventUser = ValueLabel<ID>;

export type EventUsers = EventUser[];

export interface EventFilterData {
  types: EventTypes;
  properties: EventProperties;
  locations: EventLocations;
  groups: EventGroups;
  users: EventUsers;
  ready: boolean;
}

export type SnapshotType = "image" | "video";

export interface Snapshot {
  type: SnapshotType;
  url: string;
  thumbnail?: string;
}

export interface EventLog {
  id: string;
  type: string;
  timestamp: string;
  fullLocation: string;
  snapshots: Snapshot[];
  unixDate: number;
  userId?: string;
  faceId?: string;
  facesClusterId?: string;
  videoName?: string;
}

export type EventLogs = EventLog[];

export type OrderByOption = "asc" | "desc";

export interface EventSpecificDays {
  [key: string]: ValueLabel<boolean>;
}

export interface EventSelectedFilters {
  startDate: number | null;
  endDate: number | null;
  types: EventTypes;
  groups: EventGroups;
  properties: EventProperties;
  locations: EventLocations;
  users: EventUsers;
  specificDays: EventSpecificDays;
  specificTimeFrom: Date | null;
  specificTimeTo: Date | null;
  eventsNotAssociatedWithUserFlag: boolean;
  fixedUserId: string | null;
  fixedGroupId: string | null;
}

export interface Table<T> {
  total: number;
  perPage: number;
  page: number;
  loading: boolean;
  rows: T;
  orderBy?: string;
  orderDirection?: OrderByOption;
}

export interface EventLogsTable extends Table<EventLogs> {
  orderBy: string;
  orderDirection: OrderByOption;
}

export interface EventLogResponseItem {
  id: string;
  event_type: string;
  date: number;
  property_name: string;
  location_name: string;
  user_id: string;
  cluster_user_id: string;
  snapshots?: Snapshot[];
  video_name: string;
  face_id: string;
  face_cluster_id: string;
}

export interface EventLogsAPIResponse {
  events: KeyValue<EventLogResponseItem>;
  rows_total: number;
}

export enum LockStatus {
  "NOT_REQUESTED",
  "OPENED",
  "CLOSED",
  "NOT_ACCESSIBLE",
}

export interface Lock {
  id: string;
  name: string;
  groups: string[];
  location: string;
  property: string;
  currentStatus: LockStatus;
  statusTimestamp: Date | null;
  isAccessible?: boolean;
  snapshot?: string;
}

export type Locks = KeyValue<Lock>;

export interface LockResponseItem {
  id: string;
  lock_name: string;
  all_lock_groups: string[];
  location_name: string;
  property_name: string;
  device_is_accessible: boolean;
  snapshot: string;
}

export interface LockStatusResponse {
  device_is_accessible: boolean;
  door_is_opened?: boolean;
  snapshot?: string;
}

export interface ModalState {
  isOpen: boolean;
}

export interface Task {
  id: string;
  type: string;
  targetUser: { id: string; name: string };
  task: string;
  date: string;
}

export type Tasks = Task[];

export interface TaskResponseItem {
  id: string;
  type: string;
  target_uid: string;
  target_user_name: string;
  created: number;
  name: string;
}

export type TasksResponse = KeyValue<TaskResponseItem>;

export interface AlertRule {
  id: string;
  types: string[];
  users: number[];
  groups: number[];
  time: {
    from: string;
    to: string;
  };
  locations: number[];
  channels: string[];
  days: string[];
  properties: number[];
}

export type AlertRules = AlertRule[];

export interface AlertRuleResponseItem {
  id: { value: string }[];
  event_types: { value: string }[];
  users: { target_id: string }[];
  groups: { target_id: string }[];
  from_time_string: { value: string }[];
  to_time_string: { value: string }[];
  locations: { target_id: string }[];
  channels: { value: string }[];
  days: { value: string }[];
  properties: { target_id: string }[];
}

export type AlertRulesResponse = AlertRuleResponseItem[];

export interface AttendanceReportResponseItem {
  date: number;
  users_target_id: string;
  first_seen: number;
  last_seen: number;
}

export interface AttendanceReportResponse {
  rows_total: number;
  data: AttendanceReportResponseItem[];
}

export interface AttendanceReportTableRow {
  date: string;
  userId: number;
  firstSeen: string;
  lastSeen: string;
}

export type AttendanceReportTableRows = AttendanceReportTableRow[];

export interface AttendanceReportTable
  extends Table<AttendanceReportTableRows> {}

export interface BreadcrumbGroup {
  id: string;
  name: string;
}

export interface AlertRuleModalItem {
  days: KeyValue<ValueLabel<boolean>>;
  types: string[];
  users: number[];
  groups: number[];
  time: {
    from: string;
    to: string;
  };
  locations: number[];
  channels: string[];
  id?: string;
}

export interface AlertRuleModalSelected {
  types: EventTypes;
  properties: EventProperties;
  locations: EventLocations;
  users: EventUsers;
  groups: EventGroups;
}

export interface AlertRuleForm {
  id?: string;
  smsFlag: boolean;
  emailFlag: boolean;
  selectedStartTime: FlexDate;
  selectedEndTime: FlexDate;
  selectedTypes: ValueLabel<string>[];
  selectedGroups: ValueLabel<ID>[];
  selectedProperties: ValueLabel<ID>[];
  selectedLocations: ValueLabel<ID>[];
  selectedUsers: ValueLabel<ID>[];
  days: KeyValue<ValueLabel<boolean>>;
}

export interface DailyArrivalAndDepartureReportTableRow {
  date: string;
  userId: number;
  firstSeen: string;
  lastSeen: string;
}

export type DailyArrivalAndDepartureReportTableRows =
  DailyArrivalAndDepartureReportTableRow[];

export interface DailyArrivalAndDepartureReportTable
  extends Table<DailyArrivalAndDepartureReportTableRows> {}

export interface DailyArrivalAndDepartureReportResponseItem {
  date: number;
  users_target_id: string;
  first_seen: number;
  last_seen: number;
}

export interface DailyArrivalAndDepartureReportResponse {
  rows_total: number;
  data: DailyArrivalAndDepartureReportResponseItem[];
}

type AppAction = any;

export type AppThunkAction = ThunkAction<void, RootState, unknown, AppAction>;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AppAction>;

export type AppGetState = () => RootState;

export type MaterialDatePickerChangeHandler = (
  date: MaterialUiPickersDate
) => void;

export interface FormInput<T> {
  value: T;
  isValid: boolean | null;
  onChange: React.ChangeEventHandler;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  resetValue: () => void;
}

export type FlexDate = MaterialUiPickersDate | Date | number | null;

export type ID = string | number;

export type AutoCompleteValue = ValueLabel<ID> | ValueLabel<ID>[] | null;
