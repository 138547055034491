import React from "react";

const useWindowWidth = (active: boolean) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const handler = React.useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    if (active) {
      window.addEventListener("resize", handler);
    } else {
      window.removeEventListener("resize", handler);
    }
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [active, handler]);

  return windowWidth;
};

export default useWindowWidth;
