export default {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      fontSize: 14.6,
    },
  },
};
