import React, { MouseEventHandler } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ControlButtonProps {
  title?: string;
  disabled?: boolean;
  icon: IconProp;
  label: string;
  onClick: MouseEventHandler<HTMLSpanElement>;
}

const ControlButton: React.FC<ControlButtonProps> = (props) => {
  return (
    <h6 className="text-nowrap mb-0" title={props.title}>
      <span
        className={
          props.disabled ? "clickable-span disabled" : "clickable-span"
        }
        onClick={props.disabled ? undefined : props.onClick}
      >
        <FontAwesomeIcon icon={props.icon} />
        &nbsp;{props.label}
      </span>
    </h6>
  );
};

ControlButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ControlButton;
