import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./styles/bootstrap.min.css";
import "./styles/custom.css";
import "./styles/toggle.css";
import "./styles/styles.css";
import App from "./App";
import startMockServer from "./mock";
import store from "./store";

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDom.render(app, document.getElementById("root"));

if (process.env.REACT_APP_MODE === "DEV") {
  startMockServer();
}

console.log("0006");
