import ActionType from "../actions/types";
import { KeyValue } from "../util/types";

type SelectedUnknownEventFacesState = KeyValue<{
  facesClusterImg: string;
  facesClusterId: string;
}>;

interface SelectUnknownEventFaceAction {
  type: ActionType.SELECT_UNKNOWN_EVENT_FACE;
  facesClusterImg: string;
  facesClusterId: string;
}

interface DeselectUnknownEventFaceAction {
  type: ActionType.DESELECT_UNKNOWN_EVENT_FACE;
  facesClusterId: string;
}

interface ResetUnknownEventFaceAction {
  type: ActionType.RESET_UNKNOWN_EVENT_FACES;
}

type SelectedUnknownEventFacesAction =
  | SelectUnknownEventFaceAction
  | DeselectUnknownEventFaceAction
  | ResetUnknownEventFaceAction;

const initialState: SelectedUnknownEventFacesState = {}; // event_id: {facesClusterImg, facesClusterId}

export default function selectedUnknownEventFaces(
  state: SelectedUnknownEventFacesState = { ...initialState },
  action: SelectedUnknownEventFacesAction
): SelectedUnknownEventFacesState {
  switch (action.type) {
    case ActionType.SELECT_UNKNOWN_EVENT_FACE: {
      const { facesClusterImg, facesClusterId } = action;
      return {
        ...state,
        [facesClusterId]: { facesClusterImg, facesClusterId },
      };
    }
    case ActionType.DESELECT_UNKNOWN_EVENT_FACE: {
      const _state = { ...state };
      delete _state[action.facesClusterId];
      return _state;
    }
    case ActionType.RESET_UNKNOWN_EVENT_FACES: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
