import ActionType from "../actions/types";
import type {
  DailyArrivalAndDepartureReportTable,
  DailyArrivalAndDepartureReportTableRows,
} from "../util/types";

type DailyArrivalAndDepartureReportTableState =
  DailyArrivalAndDepartureReportTable;

interface ReceiveDailyArrivalAndDepartureReportRowsAction {
  type: ActionType.RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS;
  total: number;
  rows: DailyArrivalAndDepartureReportTableRows;
}

interface ReceiveDailyArrivalAndDepartureReportRowsErrorAction {
  type: ActionType.RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS_ERROR;
}

interface SetDailyArrivalAndDepartureReportTablePerPageValAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PER_PAGE_VAL;
  perPage: number;
}

interface SetDailyArrivalAndDepartureReportTablePageValAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PAGE_VAL;
  page: number;
}

interface SetDailyArrivalAndDepartureReportTableLoadingAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_LOADING;
  loading: boolean;
}

interface ResetDailyArrivalAndDepartureReportTableAction {
  type: ActionType.RESET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE;
}

type DailyArrivalAndDepartureReportTableAction =
  | ReceiveDailyArrivalAndDepartureReportRowsAction
  | ReceiveDailyArrivalAndDepartureReportRowsErrorAction
  | SetDailyArrivalAndDepartureReportTablePerPageValAction
  | SetDailyArrivalAndDepartureReportTablePageValAction
  | SetDailyArrivalAndDepartureReportTableLoadingAction
  | ResetDailyArrivalAndDepartureReportTableAction;

const initState: DailyArrivalAndDepartureReportTableState = {
  total: 0,
  perPage: 10,
  page: 1,
  loading: false,
  rows: [],
};

export default function dailyArrivalAndDepartureReportTable(
  state: DailyArrivalAndDepartureReportTableState = initState,
  action: DailyArrivalAndDepartureReportTableAction
): DailyArrivalAndDepartureReportTableState {
  switch (action.type) {
    case ActionType.RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS:
      return {
        ...state,
        total: action.total,
        rows: action.rows,
        loading: false,
      };
    case ActionType.RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS_ERROR:
      return {
        ...state,
        total: 0,
        rows: [],
        loading: false,
      };
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PER_PAGE_VAL:
      return {
        ...state,
        perPage: action.perPage,
      };
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PAGE_VAL:
      return {
        ...state,
        page: action.page,
      };
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionType.RESET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE:
      return { ...initState };
    default:
      return state;
  }
}
