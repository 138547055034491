export default function getDateFromTimeString(timeString: string): Date {
  const mod = timeString.substring(timeString.length - 2);
  let [hrs, mins] = ["am", "pm"].includes(mod)
    ? timeString.split(mod)[0].split(":")
    : timeString.split(":");
  let hrsNum = Number(hrs),
    minsNum = Number(mins);
  if (mod === "pm") {
    hrsNum += 12;
    if (hrsNum === 24) hrsNum = 0;
  }
  const date = new Date();
  date.setHours(hrsNum);
  date.setMinutes(minsNum);
  return date;
}
