import ActionType from "../actions/types";
import type { Tasks } from "../util/types";

type TasksState = Tasks;

interface ReceiveTasksAction {
  type: ActionType.RECEIVE_TASKS;
  tasks: Tasks;
}

interface ClearTasksAction {
  type: ActionType.CLEAR_TASKS;
}

type TasksAction = ReceiveTasksAction | ClearTasksAction;

export default function tasks(
  state: TasksState = [],
  action: TasksAction
): TasksState {
  switch (action.type) {
    case ActionType.RECEIVE_TASKS:
      return action.tasks;
    case ActionType.CLEAR_TASKS:
      return [];
    default:
      return state;
  }
}
