import ActionType from "../actions/types";
import { ValueLabel } from "../util/types";

interface DailyArrivalAndDepartureReportSelectedFiltersState {
  users: ValueLabel<string>[];
  properties: ValueLabel<string>[];
  locations: ValueLabel<string>[];
}

interface SetDailyArrivalAndDepartureReportUsersAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_USERS;
  value: ValueLabel<string>[];
}

interface SetDailyArrivalAndDepartureReportPropertiesAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_PROPERTIES;
  value: ValueLabel<string>[];
}

interface SetDailyArrivalAndDepartureReportLocationsAction {
  type: ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_LOCATIONS;
  value: ValueLabel<string>[];
}

interface ResetDailyArrivalAndDepartureReportFiltersAction {
  type: ActionType.RESET_SELECTED_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_FILTERS;
}

type DailyArrivalAndDepartureReportSelectedFiltersAction =
  | SetDailyArrivalAndDepartureReportUsersAction
  | SetDailyArrivalAndDepartureReportPropertiesAction
  | SetDailyArrivalAndDepartureReportLocationsAction
  | ResetDailyArrivalAndDepartureReportFiltersAction;

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const initState: DailyArrivalAndDepartureReportSelectedFiltersState = {
  users: [],
  properties: [],
  locations: [],
};

export default function dailyArrivalAndDepartureReportSelectedFilters(
  state: DailyArrivalAndDepartureReportSelectedFiltersState = { ...initState },
  action: DailyArrivalAndDepartureReportSelectedFiltersAction
): DailyArrivalAndDepartureReportSelectedFiltersState {
  switch (action.type) {
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_USERS: {
      return {
        ...state,
        users: action.value,
      };
    }
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_PROPERTIES: {
      return {
        ...state,
        properties: action.value,
      };
    }
    case ActionType.SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_LOCATIONS: {
      return {
        ...state,
        locations: action.value,
      };
    }
    case ActionType.RESET_SELECTED_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_FILTERS: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}
