import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { MODAL_WIDTH } from "../../../util/constants";

interface CustomModalProps {
  isOpen: boolean;
  title?: string;
  children?: React.ReactNode;
  showFooter: boolean;
  cancelLabel?: string;
  confirmLabel: string;
  handleCancel?: () => void;
  handleConfirm: () => void;
  isConfirmDisabled?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  title,
  children,
  showFooter,
  cancelLabel,
  confirmLabel,
  handleCancel,
  handleConfirm,
  isConfirmDisabled,
}) => {
  const windowWidth = useWindowWidth(isOpen);
  const width = (windowWidth < MODAL_WIDTH ? windowWidth : MODAL_WIDTH) - 10;

  const toggleModal = () => {
    if (handleCancel) handleCancel();
    else handleConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      fade
      role="dialog"
      tabIndex="-1"
      style={{ width, maxWidth: width }}
      toggle={toggleModal}
    >
      {title && (
        <ModalHeader>
          <div className="modal-title">{title}</div>
        </ModalHeader>
      )}

      {children && <ModalBody>{children}</ModalBody>}

      {showFooter && (
        <ModalFooter>
          {cancelLabel && handleCancel && (
            <Button color="light" onClick={handleCancel}>
              {cancelLabel}
            </Button>
          )}
          {confirmLabel && handleConfirm && (
            <Button
              color="primary"
              onClick={handleConfirm}
              disabled={isConfirmDisabled}
            >
              {confirmLabel}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  showFooter: PropTypes.bool.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string.isRequired,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  isConfirmDisabled: PropTypes.bool,
};

export default CustomModal;
