import ActionType from "../actions/types";
import type { EventLogs, EventLogsTable, OrderByOption } from "../util/types";

type EventLogsTableState = EventLogsTable;

interface ReceiveEventLogsAction {
  type: ActionType.RECEIVE_EVENT_LOGS;
  total: number;
  events: EventLogs;
}

interface ReceiveEventLogsErrorAction {
  type: ActionType.RECEIVE_EVENT_LOGS_ERROR;
}

interface SetEventTablePerPageValAction {
  type: ActionType.SET_EVENT_TABLE_PER_PAGE_VAL;
  perPage: number;
}

interface SetEventTablePageValAction {
  type: ActionType.SET_EVENT_TABLE_PAGE_VAL;
  page: number;
}

interface SetEventTableLoadingAction {
  type: ActionType.SET_EVENT_TABLE_LOADING;
  loading: boolean;
}

interface SetEventTableOrderAction {
  type: ActionType.SET_EVENT_TABLE_ORDER;
  orderBy: string;
  orderDirection: OrderByOption;
}

interface ResetEventLogTableAction {
  type: ActionType.RESET_EVENT_LOGS_TABLE;
}

type EventLogsTableAction =
  | ReceiveEventLogsAction
  | ReceiveEventLogsErrorAction
  | SetEventTablePerPageValAction
  | SetEventTablePageValAction
  | SetEventTableLoadingAction
  | SetEventTableOrderAction
  | ResetEventLogTableAction;

const initState: EventLogsTableState = {
  total: 0,
  perPage: 10,
  page: 1,
  loading: false,
  rows: [],
  orderBy: "timestamp",
  orderDirection: "desc",
};

export default function eventLogsTable(
  state: EventLogsTableState = { ...initState },
  action: EventLogsTableAction
): EventLogsTableState {
  switch (action.type) {
    case ActionType.RECEIVE_EVENT_LOGS:
      return {
        ...state,
        total: action.total,
        rows: action.events,
        loading: false,
      };
    case ActionType.RECEIVE_EVENT_LOGS_ERROR:
      return {
        ...state,
        total: 0,
        rows: [],
        loading: false,
      };
    case ActionType.SET_EVENT_TABLE_PER_PAGE_VAL:
      return {
        ...state,
        perPage: action.perPage,
      };
    case ActionType.SET_EVENT_TABLE_PAGE_VAL:
      return {
        ...state,
        page: action.page,
      };
    case ActionType.SET_EVENT_TABLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionType.SET_EVENT_TABLE_ORDER:
      return {
        ...state,
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
        page: 1,
      };
    case ActionType.RESET_EVENT_LOGS_TABLE:
      return { ...initState };
    default:
      return state;
  }
}
