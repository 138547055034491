import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAppSelector from "../hooks/useAppSelector";

interface UnauthorizedRouteProps {
  component: React.FC<any>;
  path: string;
}

const UnauthorizedRoute: React.FC<UnauthorizedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const currentUser = useAppSelector((state) => state.currentUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/manager-dashboard" />;
        }
      }}
    />
  );
};

UnauthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default UnauthorizedRoute;
