import React from "react";
import PropTypes from "prop-types";
import useWindowWidth from "../../../hooks/useWindowWidth";
import useImageSize from "../../../hooks/useImageSize";
import { MODAL_WIDTH } from "../../../util/constants";

interface SliderImageProps {
  src: string;
}

const SliderImage: React.FC<SliderImageProps> = ({ src }) => {
  const [width, height] = useImageSize(src);
  const windowWidth = useWindowWidth(true);
  const c_width = (windowWidth < MODAL_WIDTH ? windowWidth : MODAL_WIDTH) - 10;
  const c_height = (c_width * 600) / 800;
  const styles: React.CSSProperties = {};
  if (width > height) styles.width = "100%";
  else styles.height = c_height - 30;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: styles.height ? "auto" : "100%",
      }}
    >
      <img src={src} alt={src} style={styles} />
    </div>
  );
};

SliderImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default SliderImage;
