import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  dismissSelectedUnknownEventFacesModal,
  handleAssignSelectedUnknownEventFacesToUser,
} from "../../../actions/selectedUnknownEventFacesModal";
import { deselectUnkownEventFace } from "../../../actions/selectedUnknownEventFaces";
import CustomModal from "../CustomModal";
import { Card } from "@material-ui/core";
import useAppSelector from "../../../hooks/useAppSelector";
import type { EventUser } from "../../../util/types";

const SelectedUnknownEventFacesModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector(
    (state) => state.selectedUnknownEventFacesModal.isOpen
  );
  const selectedUnknownEventFaces = useAppSelector(
    (state) => state.selectedUnknownEventFaces
  );
  const users = useAppSelector((state) => state.eventFilterData.users);
  const [selectedUser, setSelectedUser] = useState<EventUser | null>(null);

  const handleCancelClick = () => {
    setSelectedUser(null);
    dispatch(dismissSelectedUnknownEventFacesModal());
  };

  const handleConfirmClick = () => {
    dispatch(
      handleAssignSelectedUnknownEventFacesToUser(
        (selectedUser as EventUser).value
      )
    );
    setSelectedUser(null);
  };

  const handleRemoveFromSelectionClick = (facesClusterId: string) => {
    dispatch(deselectUnkownEventFace(facesClusterId));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title="Assign selected unknown faces cluster to a user"
      showFooter
      cancelLabel="Cancel"
      handleCancel={handleCancelClick}
      confirmLabel="Confirm"
      handleConfirm={handleConfirmClick}
      isConfirmDisabled={!selectedUser}
    >
      <Container>
        <Row>
          {Object.values(selectedUnknownEventFaces).map(
            (selectedUknownEventFace) => (
              <Card
                key={selectedUknownEventFace.facesClusterId}
                style={{ margin: "16px", position: "relative" }}
              >
                <img
                  src={selectedUknownEventFace.facesClusterImg as string}
                  alt={selectedUknownEventFace.facesClusterId as string}
                />
                <div style={{ textAlign: "center" }}>
                  #{selectedUknownEventFace.facesClusterId}
                </div>
                <span
                  onClick={() =>
                    handleRemoveFromSelectionClick(
                      selectedUknownEventFace.facesClusterId
                    )
                  }
                  title="Remove from selection"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
                </span>
              </Card>
            )
          )}
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ padding: 5, paddingTop: 16 }}>
              {users && users.length && (
                <Autocomplete
                  id="combo-box-demo"
                  options={users}
                  getOptionLabel={(option) => option.label}
                  style={{ width: "99%" }}
                  value={selectedUser}
                  onChange={(_, selected) => setSelectedUser(selected)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select user"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
};

export default SelectedUnknownEventFacesModal;
