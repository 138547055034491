import ActionType from "../actions/types";
import type { User, UserRelations } from "../util/types";

type UserState = User | null;

interface GetUserAction {
  type: ActionType.GET_USER;
  user: User;
}

interface ClearUserAction {
  type: ActionType.CLEAR_USER;
}

interface UpdateUserRelationsAction {
  type: ActionType.UPDATE_USER_RELATIONS;
  relations: UserRelations;
}

interface UpdateUserAvatarAction {
  type: ActionType.UPDATE_USER_AVATAR;
  avatar: string;
}

interface RemoveUserFaceClusterAction {
  type: ActionType.REMOVE_USER_FACE_CLUSTERS_ITEM;
  faceClustersId: string;
}

type UserAction =
  | GetUserAction
  | ClearUserAction
  | UpdateUserRelationsAction
  | UpdateUserAvatarAction
  | RemoveUserFaceClusterAction;

export default function user(
  state: UserState = null,
  action: UserAction
): UserState {
  switch (action.type) {
    case ActionType.GET_USER:
      return action.user;
    case ActionType.CLEAR_USER:
      return null;
    case ActionType.UPDATE_USER_RELATIONS:
      return {
        ...(state as User),
        relations: { ...state?.relations, ...action.relations },
      };
    case ActionType.UPDATE_USER_AVATAR:
      return {
        ...(state as User),
        avatar: action.avatar,
      };
    case ActionType.REMOVE_USER_FACE_CLUSTERS_ITEM: {
      const faceClusters = { ...state?.faceClusters };
      delete faceClusters[action.faceClustersId];
      return {
        ...(state as User),
        faceClusters: { ...faceClusters },
      };
    }

    default:
      return state;
  }
}
