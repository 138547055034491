import { AppThunkAction } from "../util/types";
import ActionType from "./types";

export const showConfirmModal = (action: () => Promise<any>) => ({
  type: ActionType.SHOW_CONFIRM_MODAL,
  action,
});

export const dismissConfirmModal = () => ({
  type: ActionType.DISMISS_CONFIRM_MODAL,
});

export const handleConfirmation =
  (): AppThunkAction => async (dispatch, getState) => {
    try {
      const { action } = getState().confirmModal;
      if (action) await action();
      dispatch(dismissConfirmModal());
    } catch (e) {
      console.log(e);
    }
  };
