import { toast } from "react-toastify";
import {
  getGroups,
  getUserGroups,
  getGroup,
  createSubGroup,
  addUserToGroup,
  updateGroup,
} from "../api/groups";
import { updateUserRelations } from "./user";
import ActionType from "./types";
import type { AppThunkAction, Groups, ID } from "../util/types";
import { showAddUserToGroupModal } from "./addUserToGroupModal";
import {
  handleDismissFullPageLoadingIndicator,
  showFullPageLoadingIndicator,
} from "./fullPageLoadingIndicator";

export const getGroupsAC = (groups: Groups) => ({
  type: ActionType.GET_GROUPS,
  groups,
});

export const clearGroups = () => ({
  type: ActionType.CLEAR_GROUPS,
});

const updateGroupSubgroups = ({
  groupId,
  subgroups,
}: {
  groupId: ID;
  subgroups: Groups | string[];
}) => ({
  type: ActionType.UPDATE_GROUP_SUBGROUPS,
  groupId,
  subgroups,
});

const removeSubgroup = (subgroupId: ID) => ({
  type: ActionType.REMOVE_SUBGROUP,
  subgroupId,
});

export const handleGetManagedGroups =
  (): AppThunkAction => async (dispatch, getState) => {
    try {
      const { currentUser } = getState();
      if (!currentUser) return;
      const managedGroupIds = Object.keys(currentUser.relations.managerOf);
      const groupsArr = await getGroups(managedGroupIds);
      const groups: Groups = {};
      for (let group of groupsArr) {
        groups[group.id] = group;
      }
      dispatch(getGroupsAC(groups));
    } catch (e) {
      console.log(e);
    }
  };

export const handleGetUserGroups =
  (userId: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const groupsArr = await getUserGroups(userId);
      const groups: Groups = {};
      for (let group of groupsArr) {
        groups[group.id] = group;
      }
      dispatch(getGroupsAC(groups));
    } catch (e) {
      console.log(e);
    }
  };

export const handleGetGroup =
  (
    groupId: ID,
    callback?: ({ group, error }: { group?: any; error?: any }) => void
  ): AppThunkAction =>
  async (dispatch) => {
    try {
      const group = await getGroup(groupId, true);
      for (const id of Object.keys(group.relations.managers)) {
        delete group.relations.users[id];
      }
      dispatch(getGroupsAC({ [groupId]: group }));
      if (callback) {
        callback({ group });
      }
    } catch (e) {
      if (callback) {
        callback({ error: e });
      }
      console.log("handleGetGroup:::", e);
    }
  };

export const handleGetSubGroups =
  (id: ID): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const subGroupIds = Object.keys(getState().groups[id].relations.groups);
      const groupsArr = await getGroups(subGroupIds);
      const groups: Groups = {};
      for (let group of groupsArr) {
        groups[group.id] = group;
      }
      dispatch(getGroupsAC(groups));
    } catch (e) {
      console.log(e);
    }
  };

export const handleCreateSubgroup =
  ({
    parentGroupId,
    name,
  }: {
    parentGroupId: ID;
    name: string;
  }): AppThunkAction =>
  async (dispatch) => {
    try {
      const newGroup = await createSubGroup(parentGroupId, name);
      dispatch(getGroupsAC({ [newGroup.id]: newGroup }));
    } catch (e) {
      console.log("ERROR FROM handleCreateSubgroup:::", e, e.message);
      const group = await getGroup(parentGroupId, true);
      dispatch(getGroupsAC({ [parentGroupId]: group }));
      dispatch(handleGetSubGroups(parentGroupId));
      console.log(e);
    }
    toast.success("Added successfully");
  };

export const handleAddUserToGroup =
  ({ groupId, email }: { groupId: ID; email: string }): AppThunkAction =>
  async (dispatch) => {
    dispatch(showFullPageLoadingIndicator());
    try {
      await addUserToGroup(groupId, email);
      dispatch(handleGetGroup(groupId));
      toast.success("Added successfully");
    } catch (e) {
      console.log("ERROR FROM handleAddUserToGroup:::", e);
      if (e && (e.status === 400 || e.status === 404)) {
        dispatch(showAddUserToGroupModal({ groupId, email }));
      } else {
        // cors error but worked...
        dispatch(handleGetGroup(groupId));
        toast.success("Added successfully");
      }
    }
    dispatch(handleDismissFullPageLoadingIndicator());
  };

export const handleUpdateGroup =
  ({ groupId, patch }: { groupId: ID; patch: any }): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const group = { ...getState().groups[groupId] };
      const relations = {
        groups: Object.keys(group.relations.groups),
        managers: Object.keys(group.relations.managers),
        users: Object.keys(group.relations.users),
      };
      const res = await updateGroup(groupId, { ...group, relations, ...patch });
      if (res) {
        for (let key of Object.keys(patch)) {
          patch[key] = res[key] || patch[key];
        }
        dispatch(getGroupsAC({ [groupId]: { ...group, ...patch } }));
        toast.success("Updated successfully");
      }
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
  };

export const handleGroupUserTypeToggle =
  ({ groupId, userId }: { groupId: string; userId: string }): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const group = { ...getState().groups[groupId] };
      const relations = {
        groups: Object.keys(group.relations.groups),
        managers: Object.keys(group.relations.managers),
        users: Object.keys(group.relations.users),
      };
      if (relations.managers.includes(userId)) {
        relations.managers = relations.managers.filter((id) => id !== userId);
        relations.users.push(userId);
      } else {
        relations.users = relations.users.filter((id) => id !== userId);
        relations.managers.push(userId);
      }
      const res = await updateGroup(groupId, { relations });
      if (res) {
        dispatch(handleGetGroup(groupId));
        toast.success("Updated successfully");
      }
    } catch (e) {
      console.log(e);
    }
  };

export const handleRemoveUserFromGroup =
  ({ groupId, userId }: { groupId: ID; userId: string }): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const group = { ...getState().groups[groupId] };
      if (!group) return;
      const relations = {
        groups: Object.keys(group.relations.groups),
        managers: Object.keys(group.relations.managers).filter(
          (id) => id !== userId
        ),
        users: Object.keys(group.relations.users).filter((id) => id !== userId),
      };
      const res = await updateGroup(groupId, { ...group, relations });
      if (res) {
        dispatch(handleGetGroup(groupId));
        const memberOf = { ...getState().user?.relations.memberOf };
        const managerOf = { ...getState().user?.relations.managerOf };
        delete managerOf[groupId];
        delete memberOf[groupId];
        dispatch(
          updateUserRelations({
            memberOf,
            managerOf,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

export const handleRemoveSubgroup =
  ({ groupId, subgroupId }: { groupId: ID; subgroupId: ID }): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const group = { ...getState().groups[groupId] };
      const relations = {
        groups: Object.keys(group.relations.groups).filter(
          (id) => id !== subgroupId
        ),
        managers: Object.keys(group.relations.managers),
        users: Object.keys(group.relations.users),
      };
      const res = await updateGroup(groupId, { ...group, relations });
      if (res) {
        toast.success("Updated successfully");
        dispatch(
          updateGroupSubgroups({ groupId, subgroups: relations.groups })
        );
        dispatch(removeSubgroup(subgroupId));
      }
    } catch (e) {
      console.log(e);
    }
  };
