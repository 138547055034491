import { toast } from "react-toastify";
import { getUserById, updateUser, updateUserAvatar } from "../api/users";
import { compareIds } from "../util/compare";
import type { AppThunkAction, User, UserRelations } from "../util/types";
import { setCurrentUser, updateCurrentUserAvatar } from "./currentUser";
import ActionType from "./types";

const getUser = (user: User) => ({
  type: ActionType.GET_USER,
  user,
});

export const removeUserFaceClustersItem = (faceClustersId: string) => ({
  type: ActionType.REMOVE_USER_FACE_CLUSTERS_ITEM,
  faceClustersId,
});

export const clearUser = () => ({
  type: ActionType.CLEAR_USER,
});

const updateUserAvatarAc = (avatar: string) => ({
  type: ActionType.UPDATE_USER_AVATAR,
  avatar,
});

export const handleGetUser =
  (
    id: string,
    callback: ({ user, error }: { user?: User; error?: any }) => void
  ): AppThunkAction =>
  async (dispatch) => {
    try {
      dispatch(clearUser());
      const user = await getUserById(id);
      dispatch(getUser({ ...user }));
      if (callback) {
        callback({ user });
      }
    } catch (e) {
      if (callback) {
        callback({ error: e });
      }
      console.log("handleGetUser:::", e);
    }
  };

export const updateUserRelations = (relations: UserRelations) => ({
  type: ActionType.UPDATE_USER_RELATIONS,
  relations,
});

export const handleUpdateUser =
  (patch: any): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const { user, currentUser } = { ...getState() };
      if (!(currentUser && user)) return;
      const res = await updateUser(user.id, { ...patch });
      if (res) {
        const updatedUser = { ...res, relations: user.relations };
        dispatch(getUser(updatedUser));
        if (compareIds(user.id, currentUser.id)) {
          dispatch(setCurrentUser(updatedUser));
        }
        toast.success("Updated successfully.");
      }
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
  };

export const handleUpdateUserAvatar =
  (avatar: string, callback: () => void): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const { user, currentUser } = getState();
      if (!(currentUser && user)) return;
      const base64 = avatar.replace(/^data:image\/(png|jpg);base64,/, "");
      await updateUserAvatar(user.id, base64);
      toast.success("Updated successfully.");
      dispatch(updateUserAvatarAc(avatar));
      if (compareIds(user.id, currentUser.id)) {
        dispatch(updateCurrentUserAvatar(avatar));
      }
      if (callback) callback();
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
    }
  };
