import React from "react";
import DataTable from "react-data-table-component";
import UserName from "./UserName";
import dataTableCustomStyles from "../../../styles/dataTableCustomStyles";
import useAppSelector from "../../../hooks/useAppSelector";
import type { EventLog, EventLogs } from "../../../util/types";

const columns = [
  {
    name: "Time",
    selector: "timestamp",
    sortable: true,
    id: "timestamp",
  },
  {
    name: "Type",
    selector: "type",
  },
  {
    name: "Location",
    selector: "fullLocation",
  },
  {
    name: "User",
    selector: "user",
    cell: (row: EventLog) => <UserName id={row.userId as string} />,
  },
];

const SampleRecords = () => {
  const eventLogs = useAppSelector(
    (state) => state.eventAlertRuleModal.sampleRecords
  );
  const loading = useAppSelector(
    (state) => state.eventAlertRuleModal.sampleRecordsLoading
  );

  return (
    <DataTable
      columns={columns}
      data={eventLogs as EventLogs}
      striped
      highlightOnHover
      progressPending={loading}
      customStyles={dataTableCustomStyles}
    />
  );
};

export default SampleRecords;
