import React from "react";
import useAppSelector from "./useAppSelector";

export default function useIsManager() {
  const currentUser = useAppSelector((state) => state.currentUser);

  const isManager = React.useMemo(() => {
    if (currentUser && currentUser.relations) {
      return Object.keys(currentUser.relations.managerOf).length > 0;
    }
  }, [currentUser]);

  return isManager;
}
