import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { ValueLabel } from "../../util/types";

interface WillShowNoResultsWarningProps {
  types: ValueLabel<string>[];
  hasGroups: boolean;
  hasUsers: boolean;
}

const WillShowNoResultsWarning: React.FC<WillShowNoResultsWarningProps> = ({
  types,
  hasGroups,
  hasUsers,
}) => {
  const _types = types.map((type) => type.label);
  const length = _types.length;
  const hasMotionDetected = _types.includes("Motion Detected");
  const hasPersonDetected = _types.includes("Person Detected");
  const warningFlag =
    ((length === 1 && (hasMotionDetected || hasPersonDetected)) ||
      (length === 2 && hasMotionDetected && hasPersonDetected)) &&
    (hasGroups || hasUsers);
  return warningFlag ? (
    <Alert severity="warning">
      Mixing Motion Detected/Person Detected type(s) with groups/users will
      never show any results.
    </Alert>
  ) : null;
};

WillShowNoResultsWarning.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  hasGroups: PropTypes.bool.isRequired,
  hasUsers: PropTypes.bool.isRequired,
};

export default WillShowNoResultsWarning;
