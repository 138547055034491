import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { faUserTimes } from "@fortawesome/free-solid-svg-icons";
import {
  dismissFacesClusterModal,
  handleGetFacesClusterModalImages,
  handleAssignFacesClusterToUser,
  handleUnassignFacesCluster,
} from "../../../actions/facesClusterModal";
import CustomModal from "../CustomModal";
import Faces from "../../Faces";
import ControlButton from "../../ControlButton";
import useAppSelector from "../../../hooks/useAppSelector";
import { EventUser } from "../../../util/types";
import { compareIds } from "../../../util/compare";

function useTitle(userId: string) {
  const users = useAppSelector((state) => state.eventFilterData.users);

  if (userId) {
    if (users && users.length) {
      const user = users.find(({ value }) => compareIds(value, userId));
      return user ? user.label : "";
    } else {
      return "";
    }
  } else {
    return "Assign unknown faces cluster to a user";
  }
}

const FacesClusterModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.facesClusterModal.isOpen);
  const faceId = useAppSelector((state) => state.facesClusterModal.faceId);
  const facesClusterId = useAppSelector(
    (state) => state.facesClusterModal.facesClusterId
  );
  const images = useAppSelector((state) => state.facesClusterModal.images);
  const userId = useAppSelector((state) => state.facesClusterModal.userId);
  const users = useAppSelector((state) => state.eventFilterData.users);
  const title = useTitle(userId as string);
  const [selectedUser, setSelectedUser] = useState<EventUser | null>(null);

  useEffect(() => {
    if (faceId || facesClusterId) dispatch(handleGetFacesClusterModalImages());
  }, [faceId, facesClusterId, dispatch]);

  useEffect(() => {
    if (userId)
      setSelectedUser(
        users.find(({ value }) => compareIds(value, userId)) as EventUser
      );
  }, [userId, users]);

  const handleCancelClick = () => {
    setSelectedUser(null);
    dispatch(dismissFacesClusterModal());
  };

  const handleConfirmClick = () => {
    if (selectedUser && selectedUser.value) {
      dispatch(handleAssignFacesClusterToUser(selectedUser.value));
      setSelectedUser(null);
    } else {
      dispatch(handleUnassignFacesCluster());
    }
  };

  const isConfirmDisabled: boolean = ((!userId && !selectedUser) ||
    (userId &&
      selectedUser &&
      compareIds(userId, selectedUser.value))) as boolean;

  return (
    <CustomModal
      isOpen={isOpen}
      title={title}
      showFooter
      cancelLabel="Cancel"
      handleCancel={handleCancelClick}
      confirmLabel="Confirm"
      handleConfirm={handleConfirmClick}
      isConfirmDisabled={isConfirmDisabled}
    >
      <Container>
        <Faces images={images} />
        <Row>
          <Col md={userId ? 9 : 12}>
            <div style={{ padding: 5, paddingTop: 16 }}>
              {facesClusterId && users && users.length && (
                <Autocomplete
                  id="combo-box-demo"
                  options={users}
                  getOptionLabel={(option) => option.label}
                  style={{ width: "99%" }}
                  value={selectedUser}
                  onChange={(_, selected) => setSelectedUser(selected)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select user"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </div>
          </Col>
          {userId && (
            <Col md="3">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <ControlButton
                  label="Unassign"
                  icon={faUserTimes}
                  onClick={() => setSelectedUser(null)}
                  disabled={!selectedUser}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </CustomModal>
  );
};

export default FacesClusterModal;
