export const TOKEN_KEY = "X-CSRF-TOKEN";
export const LOGOUT_TOKEN_KEY = "logout_token";
export const FACES_CLUSTER_MODAL_SOURCES = {
  eventLogsTable: "EVENT_LOGS_TABLE",
  userDashboard: "USER_DASHBOARD",
};
export const INPUT_TYPES = {
  email: "EMAIL",
};
export const MODAL_WIDTH = 1000;
