import axios, { getPrivateEndpoint } from "./index";
import { getCookie } from "../util/cookies";
import { TOKEN_KEY } from "../util/constants";

export const makeGET = (path: string) => {
  return async function (
    options: { id?: string; params?: any } = {}
  ): Promise<any> {
    const { id, params } = options;
    try {
      const endpoint = getPrivateEndpoint(path + (id ? `/${id}` : ""));
      const { data } = await axios.get(endpoint, { params });
      return data;
    } catch (e) {
      console.log(`ERROR FROM ${path}`, e, e.response, e.message);
      if (
        e.response &&
        e.response.data &&
        e.response.data.request_status_message
      ) {
        throw e.response.data.request_status_message;
      } else throw e;
    }
  };
};

export const makePOST = (path: string) => {
  return async function (body = {}, pathExt?: string): Promise<any> {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCookie(TOKEN_KEY),
        },
      };
      const endpoint = getPrivateEndpoint(path + (pathExt ? pathExt : ""));
      const { data } = await axios.post(endpoint, body, options);
      return data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.request_status_message
      ) {
        throw e.response.data.request_status_message;
      } else throw e;
    }
  };
};

export const makePATCH = (path: string) => {
  return async function (body: any, id: string): Promise<any> {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCookie(TOKEN_KEY),
        },
      };
      const endpoint = getPrivateEndpoint(path + id);
      const { data } = await axios.patch(endpoint, body, options);
      return data;
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.request_status_message
      ) {
        throw e.response.data.request_status_message;
      } else throw e;
    }
  };
};

export const makeDELETE = (path: string) => {
  return async function (id: string): Promise<any> {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCookie(TOKEN_KEY),
        },
      };
      const endpoint = getPrivateEndpoint(path + id);
      const { data } = await axios.delete(endpoint, options);
      return data;
    } catch (e) {
      console.log(`ERROR FROM ${path}`, e, e.response, e.message);
      if (
        e.response &&
        e.response.data &&
        e.response.data.request_status_message
      ) {
        throw e.response.data.request_status_message;
      } else throw e;
    }
  };
};
