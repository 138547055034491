import React from "react";
import Fade from "@material-ui/core/Fade";
import { Container, Row, Col } from "reactstrap";
import "./styles.css";

const UnauthorizedPage: React.FC = (props) => {
  return (
    <Fade in>
      <div className="unauthorized-page bg-gradient-primary fill-height">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="12" xl="10">
              {props.children}
            </Col>
          </Row>
        </Container>
      </div>
    </Fade>
  );
};

export default UnauthorizedPage;
