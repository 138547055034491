import React from "react";
import { useDispatch } from "react-redux";
import {
  dismissConfirmModal,
  handleConfirmation,
} from "../../../actions/confirmModal";
import useAppSelector from "../../../hooks/useAppSelector";
import CustomModal from "../CustomModal";

const ConfirmModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.confirmModal.isOpen);

  const handleCancelClick = () => {
    dispatch(dismissConfirmModal());
  };

  const handleConfirmClick = () => {
    dispatch(handleConfirmation());
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title="Are you sure?"
      showFooter
      cancelLabel="No, take me back"
      handleCancel={handleCancelClick}
      confirmLabel="Yes, I am sure"
      handleConfirm={handleConfirmClick}
    />
  );
};

export default ConfirmModal;
