import ActionType from "../actions/types";

interface CommonState {
  isLoaded: boolean;
}

interface SetAppIsLoaded {
  type: ActionType.SET_APP_IS_LOADED;
}

type CommonAction = SetAppIsLoaded;

const initialState: CommonState = {
  isLoaded: false,
};

export default function common(
  state: CommonState = { ...initialState },
  action: CommonAction
): CommonState {
  switch (action.type) {
    case ActionType.SET_APP_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    default:
      return state;
  }
}
