import React from "react";
import { INPUT_TYPES } from "../util/constants";
import type { FormInput } from "../util/types";
import { validateEmail } from "../util/validate";

type UseFormInput<T> = (initialValue?: T, type?: string) => FormInput<T>;

/**
 * useFormInput custom hook
 * @param {string} initialValue
 */
const useFormInput: UseFormInput<string> = (
  initialValue = "",
  type = ""
): FormInput<string> => {
  const [value, setValue] = React.useState(initialValue);
  const [isValid, setIsValid] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    setValue(initialValue);
    setIsValid(validateEmail(initialValue));
  }, [initialValue]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
    switch (type) {
      case INPUT_TYPES.email: {
        setIsValid(validateEmail(e.target.value));
        break;
      }
      default:
        break;
    }
  };

  const resetValue = () => {
    setValue(initialValue);
  };

  return {
    value,
    onChange: handleChange,
    resetValue,
    setValue,
    isValid,
  };
};

export default useFormInput;
