import React from "react";
import useAppSelector from "./useAppSelector";

export default function useIsAdmin() {
  const currentUser = useAppSelector((state) => state.currentUser);

  const isAdmin = React.useMemo(() => {
    if (currentUser && currentUser.roles) {
      return currentUser.roles.includes("administrator");
    }
  }, [currentUser]);

  return isAdmin;
}
