/*
  eventSelectedFilters
*/

import ActionType from "../actions/types";
import {
  EventGroups,
  EventLocations,
  EventProperties,
  EventSelectedFilters,
  EventSpecificDays,
  EventTypes,
  EventUsers,
} from "../util/types";

type EventSelectedFiltersState = EventSelectedFilters;

interface SetEventStartDateAction {
  type: ActionType.SET_EVENT_START_DATE;
  value: number;
}

interface SetEventEndDateAction {
  type: ActionType.SET_EVENT_END_DATE;
  value: number;
}

interface SetEventTypesAction {
  type: ActionType.SET_EVENT_TYPES;
  value: EventTypes;
}

interface SetEventGroupsAction {
  type: ActionType.SET_EVENT_GROUPS;
  value: EventGroups;
}

interface SetEventSpecificDaysAction {
  type: ActionType.SET_EVENT_SPECIFIC_DAYS;
  value: {
    key: string;
    value: boolean;
  };
}

interface SetEventSpecificTimeFromAction {
  type: ActionType.SET_EVENT_SPECIFIC_TIME_FROM;
  value: Date;
}

interface SetEventSpecificTimeToAction {
  type: ActionType.SET_EVENT_SPECIFIC_TIME_TO;
  value: Date;
}

interface SetEventPropertiesAction {
  type: ActionType.SET_EVENT_PROPERTIES;
  value: EventProperties;
}

interface SetEventLocationsAction {
  type: ActionType.SET_EVENT_LOCATIONS;
  value: EventLocations;
}

interface SetEventUsersAction {
  type: ActionType.SET_EVENT_USERS;
  value: EventUsers;
}

interface SetEventNotAssociatedWithUserFlagAction {
  type: ActionType.SET_EVENT_NOT_ASSOCIATED_WITH_USER_FLAG;
  value: boolean;
}

interface SetEventTableFilterFixedUserIDAction {
  type: ActionType.SET_EVENT_TABLE_FILTER_FIXED_USER_ID;
  userId: string;
}

interface ResetEventTableFilterFixedUserIDAction {
  type: ActionType.RESET_EVENT_TABLE_FILTER_FIXED_USER_ID;
}

interface SetEventTableFilterFixedGroupIDAction {
  type: ActionType.SET_EVENT_TABLE_FILTER_FIXED_GROUP_ID;
  groupId: string;
}

interface ResetEventTableFilterFixedGroupIDAction {
  type: ActionType.RESET_EVENT_TABLE_FILTER_FIXED_GROUP_ID;
}

interface ResetSelectedEventFilters {
  type: ActionType.RESET_SELECTED_EVENT_FILTERS;
}

type EventSelectedFiltersAction =
  | SetEventStartDateAction
  | SetEventEndDateAction
  | SetEventTypesAction
  | SetEventGroupsAction
  | SetEventSpecificDaysAction
  | SetEventSpecificTimeFromAction
  | SetEventSpecificTimeToAction
  | SetEventPropertiesAction
  | SetEventLocationsAction
  | SetEventUsersAction
  | SetEventNotAssociatedWithUserFlagAction
  | SetEventTableFilterFixedUserIDAction
  | ResetEventTableFilterFixedUserIDAction
  | SetEventTableFilterFixedGroupIDAction
  | ResetEventTableFilterFixedGroupIDAction
  | ResetSelectedEventFilters;

const DAYS: EventSpecificDays = {
  sat: {
    label: "Saturday",
    value: false,
  },
  sun: {
    label: "Sunday",
    value: false,
  },
  mon: {
    label: "Monday",
    value: false,
  },
  tue: {
    label: "Tuesday",
    value: false,
  },
  wed: {
    label: "Wednesday",
    value: false,
  },
  thu: {
    label: "Thursday",
    value: false,
  },
  fri: {
    label: "Friday",
    value: false,
  },
};

const initState: EventSelectedFiltersState = {
  startDate: null,
  endDate: null,
  types: [],
  groups: [],
  properties: [],
  locations: [],
  users: [],
  specificDays: { ...DAYS },
  specificTimeFrom: null,
  specificTimeTo: null,
  eventsNotAssociatedWithUserFlag: false,
  fixedUserId: null,
  fixedGroupId: null,
};

export default function eventSelectedFilters(
  state: EventSelectedFiltersState = initState,
  action: EventSelectedFiltersAction
): EventSelectedFiltersState {
  switch (action.type) {
    case ActionType.SET_EVENT_START_DATE: {
      return {
        ...state,
        startDate: action.value,
      };
    }
    case ActionType.SET_EVENT_END_DATE: {
      return {
        ...state,
        endDate: action.value,
      };
    }
    case ActionType.SET_EVENT_TYPES: {
      return {
        ...state,
        types: action.value,
      };
    }
    case ActionType.SET_EVENT_GROUPS: {
      return {
        ...state,
        groups: action.value,
      };
    }
    case ActionType.SET_EVENT_SPECIFIC_DAYS: {
      const { key, value } = action.value;
      return {
        ...state,
        specificDays: {
          ...state.specificDays,
          [key]: {
            ...state.specificDays[key],
            value,
          },
        },
      };
    }
    case ActionType.SET_EVENT_SPECIFIC_TIME_FROM: {
      return {
        ...state,
        specificTimeFrom: action.value,
      };
    }
    case ActionType.SET_EVENT_SPECIFIC_TIME_TO: {
      return {
        ...state,
        specificTimeTo: action.value,
      };
    }
    case ActionType.SET_EVENT_PROPERTIES: {
      return {
        ...state,
        properties: action.value,
      };
    }
    case ActionType.SET_EVENT_LOCATIONS: {
      return {
        ...state,
        locations: action.value,
      };
    }
    case ActionType.SET_EVENT_USERS: {
      return {
        ...state,
        users: action.value,
      };
    }
    case ActionType.SET_EVENT_NOT_ASSOCIATED_WITH_USER_FLAG: {
      return {
        ...state,
        users: [],
        eventsNotAssociatedWithUserFlag: action.value,
      };
    }
    case ActionType.SET_EVENT_TABLE_FILTER_FIXED_USER_ID: {
      return {
        ...state,
        fixedUserId: action.userId,
      };
    }
    case ActionType.RESET_EVENT_TABLE_FILTER_FIXED_USER_ID: {
      return {
        ...state,
        fixedUserId: null,
      };
    }
    case ActionType.SET_EVENT_TABLE_FILTER_FIXED_GROUP_ID: {
      return {
        ...state,
        fixedGroupId: action.groupId,
      };
    }
    case ActionType.RESET_EVENT_TABLE_FILTER_FIXED_GROUP_ID: {
      return {
        ...state,
        fixedGroupId: null,
      };
    }
    case ActionType.RESET_SELECTED_EVENT_FILTERS: {
      return {
        ...initState,
        fixedUserId: state.fixedUserId,
        fixedGroupId: state.fixedGroupId,
      };
    }
    default:
      return state;
  }
}
