import ActionType from "../actions/types";
import type { ModalState } from "../util/types";

interface VideoModalState extends ModalState {
  url: string | null;
  name: string;
}

interface ShowVideoModalAction {
  type: ActionType.SHOW_VIDEO_MODAL;
  url: string;
  name: string;
}

interface DismissVideoModalAction {
  type: ActionType.DISMISS_VIDEO_MODAL;
}

type VideoModalAction = ShowVideoModalAction | DismissVideoModalAction;

const initialState: VideoModalState = {
  isOpen: false,
  url: null,
  name: "",
};

export default function videoModal(
  state: VideoModalState = initialState,
  action: VideoModalAction
): VideoModalState {
  switch (action.type) {
    case ActionType.SHOW_VIDEO_MODAL:
      return {
        isOpen: true,
        url: action.url,
        name: action.name,
      };
    case ActionType.DISMISS_VIDEO_MODAL:
      return { ...initialState };
    default:
      return state;
  }
}
