import ActionType from "../actions/types";
import type { ModalState } from "../util/types";

interface ImageSliderState extends ModalState {
  images: string[];
}

interface ShowImageSliderAction {
  type: ActionType.SHOW_IMAGE_SLIDER;
  images: string[];
}

interface DismissImageSliderAction {
  type: ActionType.DISMISS_IMAGE_SLIDER;
}

type ImageSliderAction = ShowImageSliderAction | DismissImageSliderAction;

const initialState: ImageSliderState = {
  isOpen: false,
  images: [],
};

export default function imageSlider(
  state: ImageSliderState = initialState,
  action: ImageSliderAction
): ImageSliderState {
  switch (action.type) {
    case ActionType.SHOW_IMAGE_SLIDER:
      return {
        isOpen: true,
        images: action.images,
      };
    case ActionType.DISMISS_IMAGE_SLIDER:
      return { ...initialState };
    default:
      return state;
  }
}
