import { toast } from "react-toastify";
import { getEventLogsCombined } from "../api/events";
import { createAlertRule, updateAlertRule } from "../api/alertRules";
import { handleReceiveEventRules } from "./alertRules";
import formatDate, { getTime24 } from "../util/formatDate";
import ActionType from "./types";
import {
  AlertRule,
  AlertRuleForm,
  AlertRuleModalItem,
  AlertRuleModalSelected,
  AppThunkAction,
  EventGroups,
  EventLocations,
  EventLogs,
  EventLogsAPIResponse,
  EventProperties,
  EventTypes,
  EventUsers,
  ID,
  KeyValue,
  ValueLabel,
} from "../util/types";
import { compareIds } from "../util/compare";

const DAYS = {
  sat: {
    label: "Saturday",
    value: false,
  },
  sun: {
    label: "Sunday",
    value: false,
  },
  mon: {
    label: "Monday",
    value: false,
  },
  tue: {
    label: "Tuesday",
    value: false,
  },
  wed: {
    label: "Wednesday",
    value: false,
  },
  thu: {
    label: "Thursday",
    value: false,
  },
  fri: {
    label: "Friday",
    value: false,
  },
};

const setEventAlertRuleModalSampleRecordsLoading = (
  sampleRecordsLoading: boolean
) => ({
  type: ActionType.SET_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS_LOADIG,
  sampleRecordsLoading,
});

const receiveEventAlertRuleModalSampleRecords = (sampleRecords: EventLogs) => ({
  type: ActionType.RECEIVE_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS,
  sampleRecords,
});

const setEventAlertRuleModalSelectedItem = ({
  key,
  val,
}: {
  key: string;
  val: any;
}) => ({
  type: ActionType.SET_EVENT_ALERT_RULE_MODAL_SELECTED_ITEM,
  key,
  val,
});

const showEventAlertRuleModal = ({
  alertRule,
  selected,
}: {
  alertRule: AlertRuleModalItem;
  selected: AlertRuleModalSelected;
}) => ({
  type: ActionType.SHOW_EVENT_ALERT_RULE_MODAL,
  alertRule,
  selected,
});

export const showEmptyEvetAlertRuleModal = () => ({
  type: ActionType.SHOW_EMPTY_EVENT_ALERT_RULE_MODAL,
});

export const handleSetEventAlertRuleModalSelectedItem =
  (key: string, val: any): AppThunkAction =>
  (dispatch) => {
    dispatch(setEventAlertRuleModalSelectedItem({ key, val }));
    dispatch(handleReceiveEventAlertRuleModalSampleRecords());
  };

let handleReceiveSampleRecordsCallId;

export const handleReceiveEventAlertRuleModalSampleRecords =
  (): AppThunkAction => async (dispatch, getState) => {
    const _callId = Date.now();
    handleReceiveSampleRecordsCallId = _callId;
    try {
      dispatch(setEventAlertRuleModalSampleRecordsLoading(true));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      const now = new Date();
      const { types, properties, locations, groups, users } =
        getState().eventAlertRuleModal.selected;
      let _locationIdsByProperties: number[] = [];
      if (properties.length) {
        const { eventFilterData } = getState();
        _locationIdsByProperties = eventFilterData.locations
          .filter((locationData) =>
            properties.find(
              (property) => property.label === locationData.label.split(":")[0]
            )
          )
          .map(({ value }) => value);
      }
      const body = {
        groups: groups.map(({ value }) => value),
        event_types: types.map(({ value }) => value),
        locations: [..._locationIdsByProperties].concat(
          locations.map(({ value }) => value)
        ),
        users: users.map(({ value }) => value),
        date_unix_from: String(Number(lastMonth) / 1000),
        date_unix_to: String(Number(now) / 1000),
        rows_from: 0,
        rows_limit: 3,
      };
      const { combined_events }: { combined_events: EventLogsAPIResponse } =
        await getEventLogsCombined(body);
      if (handleReceiveSampleRecordsCallId !== _callId) return;
      if (combined_events && combined_events.events) {
        const events: EventLogs = Object.values(combined_events.events).map(
          (event) => ({
            id: event.id,
            type: event.event_type,
            timestamp: formatDate(event.date * 1000),
            fullLocation: `${event.property_name}: ${event.location_name}`,
            userId: event.user_id || event.cluster_user_id,
            snapshots: event.snapshots || [],
            unixDate: Number(event.date),
            videoName: event.video_name,
            faceId: event.face_id,
            facesClusterId: event.face_cluster_id,
          })
        );
        dispatch(
          receiveEventAlertRuleModalSampleRecords(
            events.sort((a, b) => a.unixDate - b.unixDate)
          )
        );
      } else {
        dispatch(receiveEventAlertRuleModalSampleRecords([]));
      }
    } catch (e) {
      console.log(e);
      if (handleReceiveSampleRecordsCallId !== _callId) return;
      dispatch(setEventAlertRuleModalSampleRecordsLoading(false));
    }
  };

export const handleShowEventAlertRuleModal =
  (alertRule: AlertRule): AppThunkAction =>
  (dispatch, getState) => {
    const { types, locations, users, groups, properties } =
      getState().eventFilterData;
    const days: KeyValue<ValueLabel<boolean>> = { ...DAYS };
    Object.keys(days).forEach((key) => {
      days[key].value = alertRule.days.find(
        (alertRuleDay) => alertRuleDay === days[key].label
      )
        ? true
        : false;
    });
    const selected: AlertRuleModalSelected = {
      types: [
        ...alertRule.types.map((id) =>
          types.find((item) => compareIds(item.value, id))
        ),
      ] as EventTypes,
      properties: [
        ...alertRule.properties.map((id) =>
          properties.find((item) => compareIds(item.value, id))
        ),
      ] as EventProperties,
      locations: [
        ...alertRule.locations.map((id) =>
          locations.find((item) => compareIds(item.value, id))
        ),
      ] as EventLocations,
      users: [
        ...alertRule.users.map((id) =>
          users.find((item) => compareIds(item.value, id))
        ),
      ] as EventUsers,
      groups: [
        ...alertRule.groups.map((id) =>
          groups.find((item) => compareIds(item.value, id))
        ),
      ] as EventGroups,
    };
    dispatch(
      showEventAlertRuleModal({
        alertRule: { ...alertRule, days },
        selected,
      })
    );
  };

export const dismissEventAlertRuleModal = () => ({
  type: ActionType.DISMISS_EVENT_ALERT_RULE_MODAL,
});

export const handleAlertRuleModalConfirmation =
  (alertRule: AlertRuleForm, callback: () => void): AppThunkAction =>
  async (dispatch) => {
    try {
      const channels = [];
      if (alertRule.smsFlag) channels.push("SMS");
      if (alertRule.emailFlag) channels.push("Email");
      const body = {
        from: alertRule.selectedStartTime
          ? getTime24(alertRule.selectedStartTime as Date)
          : "00:00",
        to: alertRule.selectedEndTime
          ? getTime24(alertRule.selectedEndTime as Date)
          : "23:59",
        event_types: mapListForEventRuleRequestBody(alertRule.selectedTypes),
        days: mapDaysForAlertRuleRequestBody(alertRule.days),
        channels,
        groups: mapListForEventRuleRequestBody(alertRule.selectedGroups),
        properties: mapListForEventRuleRequestBody(
          alertRule.selectedProperties
        ),
        locations: mapListForEventRuleRequestBody(alertRule.selectedLocations),
        users: mapListForEventRuleRequestBody(alertRule.selectedUsers),
      };
      let response;
      if (alertRule.id) {
        response = await updateAlertRule(body, alertRule.id);
      } else {
        response = await createAlertRule(body);
      }
      const { request_status, message } = response;
      if (request_status) {
        dispatch(handleReceiveEventRules());
        toast.success(message);
      } else {
        toast.error(message);
      }
      dispatch(dismissEventAlertRuleModal());
      if (callback) callback();
    } catch (e) {
      console.log(e);
    }
  };

function mapListForEventRuleRequestBody(list: ValueLabel<ID>[]) {
  return list.length ? list.map((type) => type.value) : [null];
}

function mapDaysForAlertRuleRequestBody(days: KeyValue<ValueLabel<boolean>>) {
  const _days = Object.values(days)
    .filter(({ value }) => value)
    .map(({ label }) => label);
  return _days.length ? _days : [null];
}
