import React from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { dismissAddUserToGroupModal } from "../../../actions/addUserToGroupModal";
import { handleAddUserToGroup } from "../../../actions/addUserToGroupModal";
import useAppSelector from "../../../hooks/useAppSelector";
import useFormInput from "../../../hooks/useFormInput";
import CustomModal from "../CustomModal";

const AddUserToGroupModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.addUserToGroupModal.isOpen);
  const email = useAppSelector((state) => state.addUserToGroupModal.email);
  const firstName = useFormInput("");
  const lastName = useFormInput("");

  const handleCancelClick = () => {
    dispatch(dismissAddUserToGroupModal());
  };

  const handleConfirmClick = () => {
    dispatch(
      handleAddUserToGroup({
        firstName: firstName.value,
        lastName: lastName.value,
      })
    );
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create and add user to group"
      showFooter
      cancelLabel="Cancel"
      handleCancel={handleCancelClick}
      confirmLabel="Confirm"
      handleConfirm={handleConfirmClick}
      isConfirmDisabled={!(firstName.value && lastName.value)}
    >
      <p>{email}</p>
      <FormGroup>
        <Input
          className="form-control form-control-user"
          type="text"
          placeholder="First Name"
          onChange={firstName.onChange}
          value={firstName.value}
        />
      </FormGroup>
      <FormGroup>
        <Input
          className="form-control form-control-user"
          type="text"
          placeholder="Last Name"
          onChange={lastName.onChange}
          value={lastName.value}
        />
      </FormGroup>
    </CustomModal>
  );
};

export default AddUserToGroupModal;
