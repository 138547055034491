enum ActionType {
  /* CurrentUser Action Types*/
  SET_CURRENT_USER = "SET_CURRENT_USER",
  UNSET_CURRENT_USER = "UNSET_CURRENT_USER",
  UPDATE_CURRENT_USER_AVATAR = "UPDATE_CURRENT_USER_AVATAR",

  /* Groups Action Types */
  GET_GROUPS = "GET_GROUPS",
  CLEAR_GROUPS = "CLEAR_GROUPS",
  CREATE_SUBGROUP = "CREATE_SUBGROUP",
  ADD_USER_TO_GROUP = "ADD_USER_TO_GROUP",
  UPDATE_GROUP_SUBGROUPS = "UPDATE_GROUP_SUBGROUPS",
  REMOVE_SUBGROUP = "REMOVE_SUBGROUP",

  /* User Action Types */
  GET_USER = "GET_USER",
  CLEAR_USER = "CLEAR_USER",
  UPDATE_USER_RELATIONS = "UPDATE_USER_RELATIONS",
  UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR",
  REMOVE_USER_FACE_CLUSTERS_ITEM = "REMOVE_USER_FACE_CLUSTERS_ITEM",

  /* Event Filter Data Action Types */
  RECEIVE_EVENT_TYPES = "RECEIVE_EVENT_TYPES",
  RECEIVE_EVENT_LOCATIONS = "RECEIVE_EVENT_LOCATIONS",
  RECEIVE_EVENT_PROPERTIES = "RECEIVE_EVENT_PROPERTIES",
  RECEIVE_EVENT_GROUPS = "RECEIVE_EVENT_GROUPS",
  RECEIVE_EVENT_USERS = "RECEIVE_EVENT_USERS",
  EVENT_FILTER_READY = "EVENT_FILTER_READY",

  /* Event Logs Action Types */
  RECEIVE_EVENT_LOGS = "GET_EVENT_LOGS",
  RESET_EVENT_LOGS_TABLE = "RESET_EVENT_LOGS_TABLE",
  SET_EVENT_START_DATE = "SET_EVENT_START_DATE",
  SET_EVENT_END_DATE = "SET_EVENT_END_DATE",
  SET_EVENT_TYPES = "SET_EVENT_TYPES",
  SET_EVENT_GROUPS = "SET_EVENT_GROUPS",
  SET_EVENT_PROPERTIES = "SET_EVENT_PROPERTIES",
  SET_EVENT_LOCATIONS = "SET_EVENT_LOCATIONS",
  SET_EVENT_USERS = "SET_EVENT_USERS",
  SET_EVENT_NOT_ASSOCIATED_WITH_USER_FLAG = "SET_EVENT_NOT_ASSOCIATED_WITH_USER_FLAG",
  RESET_SELECTED_EVENT_FILTERS = "RESET_SELECTED_EVENT_FILTERS",
  SET_EVENT_TABLE_PER_PAGE_VAL = "SET_EVENT_TABLE_PER_PAGE_VAL",
  SET_EVENT_TABLE_PAGE_VAL = "SET_EVENT_TABLE_PAGE_VAL",
  SET_EVENT_TABLE_LOADING = "SET_EVENT_TABLE_LOADING",
  SET_EVENT_TABLE_FILTER_FIXED_USER_ID = "SET_EVENT_TABLE_FILTER_FIXED_USER_ID",
  RESET_EVENT_TABLE_FILTER_FIXED_USER_ID = "RESET_EVENT_TABLE_FILTER_FIXED_USER_ID",
  SET_EVENT_TABLE_FILTER_FIXED_GROUP_ID = "SET_EVENT_TABLE_FILTER_FIXED_GROUP_ID",
  RESET_EVENT_TABLE_FILTER_FIXED_GROUP_ID = "RESET_EVENT_TABLE_FILTER_FIXED_GROUP_ID",
  SET_EVENT_TABLE_ORDER = "SET_EVENT_TABLE_ORDER",
  RECEIVE_EVENT_LOGS_ERROR = "RECEIVE_EVENT_LOGS_ERROR",
  SET_EVENT_SPECIFIC_DAYS = "SET_EVENT_SPECIFIC_DAYS",
  SET_EVENT_SPECIFIC_TIME_FROM = "SET_EVENT_SPECIFIC_TIME_FROM",
  SET_EVENT_SPECIFIC_TIME_TO = "SET_EVENT_SPECIFIC_TIME_TO",

  /* Locks Action Types */
  RECEIVE_LOCKS = "RECEIVE_LOCKS",
  RECEIVE_LOCK_STATUS = "RECEIVE_LOCK_STATUS",
  MAKE_LOCK_ACTION = "MAKE_LOCK_ACTION",
  RESET_LOCK_STATUS = "RESET_LOCK_STATUS",
  CLEAR_LOCKS = "CLEAR_LOCKS",

  /* Add User To Group Modal Action Types */
  SHOW_ADD_USER_TO_GROUP_MODAL = "SHOW_ADD_USER_TO_GROUP_MODAL",
  DISMISS_ADD_USER_TO_GROUP_MODAL = "DISMISS_ADD_USER_TO_GROUP_MODAL",

  /* Tasks Action Types */
  RECEIVE_TASKS = "RECEIVE_TASKS",
  CLEAR_TASKS = "CLEAR_TASKS",

  /* Alert Rules Action Types */
  RECEIVE_ALERT_RULES = "RECEIVE_ALERT_RULES",

  /* Faces Cluster Modal Action Types */
  SHOW_FACES_CLUSTER_MODAL = "SHOW_FACES_CLUSTER_MODAL",
  DISMISS_FACES_CLUSTER_MODAL = "DISMISS_FACES_CLUSTER_MODAL",
  GET_FACES_CLUSTER_MODAL_IMAGES = "GET_FACES_CLUSTER_MODAL_IMAGES",
  ASSIGN_UNKOWN_FACES_TO_USER = "ASSIGN_UNKOWN_FACES_TO_USER",
  SET_FACES_CLUSTER_MODAL_FACES_CLUSTER_ID = "SET_FACES_CLUSTER_MODAL_FACES_CLUSTER_ID",

  /* Full Page Loading Indicator Action Types */
  SHOW_FULL_PAGE_LOADING_INDICATOR = "SHOW_FULL_PAGE_LOADING_INDICATOR",
  DISMISS_FULL_PAGE_LOADING_INDICATOR = "DISMISS_FULL_PAGE_LOADING_INDICATOR",
  HIDE_FULL_PAGE_LOADING_INDICATOR = "HIDE_FULL_PAGE_LOADING_INDICATOR",

  /* Selected Unknown Event Faces Action Types */
  SELECT_UNKNOWN_EVENT_FACE = "SELECT_UNKNOWN_EVENT_FACE",
  DESELECT_UNKNOWN_EVENT_FACE = "DESELECT_UNKNOWN_EVENT_FACE",
  RESET_UNKNOWN_EVENT_FACES = "RESET_UNKNOWN_EVENT_FACES",

  /* Selected Unknown Event Faces Modal Action Types */
  SHOW_SELECTED_UNKNOWN_EVET_FACES_MODAL = "SHOW_SELECTED_UNKNOWN_EVET_FACES_MODAL",
  DISMISS_SELECTED_UNKNOWN_EVET_FACES_MODAL = "DISMISS_SELECTED_UNKNOWN_EVET_FACES_MODAL",
  ASSIGN_SELECTED_UNKNOWN_EVET_FACES_TO_USER = "ASSIGN_SELECTED_UNKNOWN_EVET_FACES_TO_USER",

  /* Attendance Report Table Action Types */
  SET_ATTENDANCE_REPORT_START_DATE = "SET_ATTENDANCE_REPORT_START_DATE",
  SET_ATTENDANCE_REPORT_END_DATE = "SET_ATTENDANCE_REPORT_END_DATE",
  SET_ATTENDANCE_REPORT_USERS = "SET_ATTENDANCE_REPORT_USERS",
  SET_ATTENDANCE_REPORT_GROUPS = "SET_ATTENDANCE_REPORT_GROUPS",
  RESET_SELECTED_ATTENDANCE_REPORT_FILTERS = "RESET_SELECTED_ATTENDANCE_REPORT_FILTERS",
  RECEIVE_ATTENDANCE_REPORT_ROWS = "RECEIVE_ATTENDANCE_REPORT_ROWS",
  RESET_ATTENDANCE_REPORT_TABLE = "RESET_ATTENDANCE_REPORT_TABLE",
  SET_ATTENDANCE_REPORT_TABLE_PER_PAGE_VAL = "SET_ATTENDANCE_REPORT_TABLE_PER_PAGE_VAL",
  SET_ATTENDANCE_REPORT_TABLE_PAGE_VAL = "SET_ATTENDANCE_REPORT_TABLE_PAGE_VAL",
  SET_ATTENDANCE_REPORT_TABLE_LOADING = "SET_ATTENDANCE_REPORT_TABLE_LOADING",
  RECEIVE_ATTENDANCE_REPORT_ROWS_ERROR = "RECEIVE_ATTENDANCE_REPORT_ROWS_ERROR",

  /* Image Slider Action Types */
  SHOW_IMAGE_SLIDER = "SHOW_IMAGE_SLIDER",
  DISMISS_IMAGE_SLIDER = "DISMISS_IMAGE_SLIDER",

  /* Video Modal Action Types */
  SHOW_VIDEO_MODAL = "SHOW_VIDEO_MODAL",
  DISMISS_VIDEO_MODAL = "DISMISS_VIDEO_MODAL",

  /* Confirm Modal Action Types */
  SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL",
  DISMISS_CONFIRM_MODAL = "DISMISS_CONFIRM_MODAL",

  /* Breadcrumb Action Types */
  APPEND_TO_BREADCRUMB = "APPEND_TO_BREADCRUMB",
  CLEAR_BREADCRUMB = "CLEAR_BREADCRUMB",

  /* Event Alert Rule Modal */
  SHOW_EVENT_ALERT_RULE_MODAL = "SHOW_EVENT_ALERT_RULE_MODAL",
  DISMISS_EVENT_ALERT_RULE_MODAL = "DISMISS_EVENT_ALERT_RULE_MODAL",
  SET_EVENT_ALERT_RULE_MODAL_SELECTED_ITEM = "SET_EVENT_ALERT_RULE_MODAL_SELECTED_ITEM",
  SHOW_EMPTY_EVENT_ALERT_RULE_MODAL = "SHOW_EMPTY_EVENT_ALERT_RULE_MODAL",
  RECEIVE_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS = "RECEIVE_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS",
  SET_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS_LOADIG = "SET_EVENT_ALERT_RULE_MODAL_SAMPLE_RECORDS_LOADIG",

  /* Daily Arrival And Departure Report Selected Filters Action Types */
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_USERS = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_USERS",
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_PROPERTIES = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_PROPERTIES",
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_LOCATIONS = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_LOCATIONS",
  RESET_SELECTED_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_FILTERS = "RESET_SELECTED_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_FILTERS",
  RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS = "RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS",
  RESET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE = "RESET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE",
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PER_PAGE_VAL = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PER_PAGE_VAL",
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PAGE_VAL = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_PAGE_VAL",
  SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_LOADING = "SET_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_TABLE_LOADING",
  RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS_ERROR = "RECEIVE_DAILY_ARRIVAL_AND_DEPARTURE_REPORT_ROWS_ERROR",

  /* Common Action Types */
  SET_APP_IS_LOADED = "SET_APP_IS_LOADED",
}

export default ActionType;
