import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import FacesToggleButton from "./FacesToggleButton";

interface FacesProps {
  images: string[];
}

const Faces: React.FC<FacesProps> = ({ images }) => {
  const [showMoreFlag, setShowMoreFlag] = useState(false);

  const _images = showMoreFlag
    ? [...images]
    : [...images].filter((_, index) => index < 8);

  return (
    <React.Fragment>
      <Row style={{ minHeight: 320 }}>
        {_images.map((url, index) => (
          <Col key={index} md="3">
            <img
              style={{ padding: 5 }}
              key={index}
              alt={`img-${index}`}
              src={url}
            />
          </Col>
        ))}
      </Row>
      {images.length > 8 && (
        <React.Fragment>
          {!showMoreFlag && (
            <FacesToggleButton
              label="Show more?"
              onClick={() => setShowMoreFlag(true)}
            />
          )}
          {showMoreFlag && (
            <FacesToggleButton
              label="Show less?"
              onClick={() => setShowMoreFlag(false)}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Faces.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Faces;
