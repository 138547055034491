import axios from "./index";
import { getPrivateEndpoint } from "./index";
import { getUserById } from "./users";
import { getCookie } from "../util/cookies";
import { TOKEN_KEY } from "../util/constants";
import { ID } from "../util/types";

/**
 * fetch group by groupId
 * @param {userId} userId
 */
export async function getGroup(id: ID, userDetails: boolean) {
  try {
    let url = `groups/${id}`;
    if (userDetails) url += "/with-users-details";
    const endpoint = getPrivateEndpoint(url);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    throw e;
  }
}

/**
 * fetch groups by userId
 * @param {userId} userId
 */
export async function getUserGroups(userId: string) {
  try {
    const user = await getUserById(userId);
    const { managerOf, memberOf } = user.relations;
    const groups = { ...managerOf, ...memberOf };
    const groupIds = Object.keys(groups);
    return await getGroups(groupIds);
  } catch (e) {
    throw e;
  }
}

/**
 * fetch groups by group ids
 * @param {groupIds} ids
 */
export async function getGroups(ids: string[]) {
  try {
    const requestArr = [];
    for (let id of ids) {
      const endpoint = getPrivateEndpoint(`groups/${id}`);
      requestArr.push(axios.get(endpoint));
    }
    const result = await axios.all(requestArr);
    return result.map((item) => item.data);
  } catch (e) {
    throw e;
  }
}

/**
 * create sub group
 * @param {userId} userId
 */
export async function createSubGroup(parentGroupId: ID, name: string) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${parentGroupId}`);
    const body = {
      name,
      suite: null,
      phoneNumbers: [],
    };
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    const { data } = await axios.post(endpoint, body, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM createSubGroup:::", e, e.response, e.message);
    throw e;
  }
}

/**
 * add a user to a group
 * @param {groupId} groupId
 * @param {email} email
 */
export async function addUserToGroup(groupId: ID, email: string) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${groupId}/user`);
    const body = { email, user_type: "member" };
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    const { data } = await axios.post(endpoint, body, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM addUserToGroup:::", e, e.response, e.message);
    throw e.response;
  }
}

interface NewUser {
  email: string;
  first_name: string;
  last_name: string;
}

/**
 * add a new user to a group
 * @param {groupId} groupId
 * @param {newUser} newUser
 */
export async function addNewUserToGroup(groupId: string, newUser: NewUser) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${groupId}/user`);
    const body = { ...newUser, user_type: "member", create_new: true };
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    const { data } = await axios.post(endpoint, body, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM addUserToGroup:::", e, e.response, e.message);
    throw e;
  }
}

/**
 * update a group
 * @param {groupId} groupId
 * @param {group} group
 */
export async function updateGroup(groupId: ID, group: any) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${groupId}`);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    delete group["entity_type"];
    const { data } = await axios.patch(endpoint, group, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM updateGroup:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function removeGroup(groupId: ID) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${groupId}/force`);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    await updateGroup(groupId, {
      relations: { managers: [], users: [], groups: [] },
    });
    const { data } = await axios.delete(endpoint, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM removeGroup:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function getGroupAccessLogs(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${id}/activities`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    console.log("ERROR FROM getGroupAccessLogs:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function getGroupEventLogs(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`groups/${id}/activities`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    console.log("ERROR FROM getGroupEventLogs:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}
