import ActionType from "../actions/types";
import { BreadcrumbGroup } from "../util/types";

type BreadcrumbState = BreadcrumbGroup[];

interface AppendToBreadcrumbAction {
  type: ActionType.APPEND_TO_BREADCRUMB;
  group: BreadcrumbGroup;
}

interface ClearBreadcrumbAction {
  type: ActionType.CLEAR_BREADCRUMB;
}

type BreadcrumbAction = AppendToBreadcrumbAction | ClearBreadcrumbAction;

export default function breadcrumb(
  state: BreadcrumbState = [],
  action: BreadcrumbAction
): BreadcrumbState {
  switch (action.type) {
    case ActionType.APPEND_TO_BREADCRUMB:
      return [action.group, ...state];
    case ActionType.CLEAR_BREADCRUMB:
      return [];
    default:
      return state;
  }
}
