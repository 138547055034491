export default function downloadFile(url: string) {
  try {
    const urlSplitted = url.split("/");
    const fileName = urlSplitted[urlSplitted.length - 1];
    var a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    a.setAttribute("download", fileName);
    a.click();
  } catch (e) {
    console.log(e);
  }
}
