import React, { useState, useEffect, ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Container, Col, Row, FormGroup, Label, Input } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import {
  dismissEventAlertRuleModal,
  handleAlertRuleModalConfirmation,
  handleSetEventAlertRuleModalSelectedItem,
  handleReceiveEventAlertRuleModalSampleRecords,
} from "../../../actions/eventAlertRuleModal";
import getDateFromTimeString from "../../../util/getDateFromTimeString";
import CustomAutocomplete from "../../CustomAutocomplete";
import CustomModal from "../CustomModal";
import SampleRecords from "./SampleRecords";
import WillShowNoResultsWarningContainer from "./WillShowNoResultsWarningContainer";
import DaysCheckboxes from "../../DaysCheckboxes";
import useAppSelector from "../../../hooks/useAppSelector";
import type { EventSpecificDays, FlexDate } from "../../../util/types";

const EventAlertRuleModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.eventAlertRuleModal.isOpen);
  const types = useAppSelector((state) => state.eventFilterData.types);
  const groups = useAppSelector((state) => state.eventFilterData.groups);
  const properties = useAppSelector(
    (state) => state.eventFilterData.properties
  );
  const locations = useAppSelector((state) => state.eventFilterData.locations);
  const users = useAppSelector((state) => state.eventFilterData.users);
  const alertRule = useAppSelector(
    (state) => state.eventAlertRuleModal.alertRule
  );
  const [selectedStartTime, setSelectedStartTime] = useState<FlexDate>(
    new Date()
  );
  const [selectedEndTime, setSelectedEndTime] = useState<FlexDate>(new Date());
  const selectedTypes = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.types
  );
  const selectedGroups = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.groups
  );
  const selectedProperties = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.properties
  );
  const selectedLocations = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.locations
  );
  const selectedUsers = useAppSelector(
    (state) => state.eventAlertRuleModal.selected.users
  );
  const [smsFlag, setSmsFlag] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  const [days, setDays] = useState<EventSpecificDays>({});

  useEffect(() => {
    if (isOpen) {
      setSelectedStartTime(getDateFromTimeString(alertRule.time.from));
      setSelectedEndTime(getDateFromTimeString(alertRule.time.to));
      setSmsFlag(alertRule.channels.includes("SMS"));
      setEmailFlag(alertRule.channels.includes("Email"));
      setDays(alertRule.days);
      dispatch(handleReceiveEventAlertRuleModalSampleRecords());
    }
  }, [isOpen, alertRule, dispatch]);

  const resetInputs = () => {
    setSelectedStartTime(new Date());
    setSelectedEndTime(new Date());
    setSmsFlag(false);
    setEmailFlag(false);
  };

  const handleCancelClick = () => {
    dispatch(dismissEventAlertRuleModal());
    resetInputs();
  };

  const handleConfirmClick = () => {
    dispatch(
      handleAlertRuleModalConfirmation(
        {
          selectedStartTime,
          selectedEndTime,
          selectedTypes,
          selectedGroups,
          selectedProperties,
          selectedLocations,
          selectedUsers,
          smsFlag,
          emailFlag,
          days,
          id: alertRule.id,
        },
        resetInputs
      )
    );
  };

  const handleDayChange = (key: string, value: any) => {
    setDays((prevState) => ({
      ...prevState,
      [key]: {
        ...days[key],
        value,
      },
    }));
  };

  const handleItemChange = (key: string, val: any) => {
    dispatch(handleSetEventAlertRuleModalSelectedItem(key, val));
  };

  const handleSMSToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSmsFlag(e.target.checked);
  };

  const handleEmailToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmailFlag(e.target.checked);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title="Event Alert Rule"
      showFooter
      cancelLabel="Cancel"
      handleCancel={handleCancelClick}
      confirmLabel="Confirm"
      handleConfirm={handleConfirmClick}
      isConfirmDisabled={!(smsFlag || emailFlag)}
    >
      <Container>
        <Row>
          <Col md="12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: "80%",
                padding: 5,
                paddingTop: 16,
              }}
            >
              <h6 className="mb-0">From</h6>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="start-time-picker"
                  value={selectedStartTime}
                  onChange={(d: FlexDate) => setSelectedStartTime(d)}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
              <h6 className="mb-0">To</h6>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="end-time-picker"
                  value={selectedEndTime}
                  onChange={setSelectedEndTime}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ padding: 5, paddingTop: 16, display: "flex" }}>
              <h6 className="mb-0" style={{ width: 80 }}>
                Days
              </h6>
              <DaysCheckboxes days={days} handleChange={handleDayChange} />
            </div>
            <div style={{ padding: 5, paddingTop: 24, display: "flex" }}>
              <h6 className="mb-0" style={{ width: 80 }}>
                Channels
              </h6>
              <Container>
                <Row>
                  <Col md="4">
                    <FormGroup
                      check
                      className="input-section-checkbox-container"
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          className="input-section-checkbox"
                          checked={smsFlag}
                          onChange={handleSMSToggle}
                        />
                        &nbsp;&nbsp;SMS
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup
                      check
                      className="input-section-checkbox-container"
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          className="input-section-checkbox"
                          checked={emailFlag}
                          onChange={handleEmailToggle}
                        />
                        &nbsp;&nbsp;Email
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Container>
            </div>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <CustomAutocomplete
                label="Select type(s)"
                placeholder="Type"
                options={types}
                value={selectedTypes}
                handleChange={(data) => handleItemChange("types", data)}
                multiSelect={true}
              />
            </div>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <CustomAutocomplete
                label="Select group(s)"
                placeholder="Group"
                options={groups}
                value={selectedGroups}
                handleChange={(data) => handleItemChange("groups", data)}
                multiSelect={true}
              />
            </div>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <CustomAutocomplete
                label="Select property(s)"
                placeholder="Property"
                options={properties}
                value={selectedProperties}
                handleChange={(data) => handleItemChange("properties", data)}
                multiSelect={true}
              />
            </div>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <CustomAutocomplete
                label="Select location(s)"
                placeholder="Location"
                options={locations}
                value={selectedLocations}
                handleChange={(data) => handleItemChange("locations", data)}
                multiSelect={true}
              />
            </div>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <CustomAutocomplete
                label="Select user(s)"
                placeholder="User"
                options={users}
                value={selectedUsers}
                handleChange={(data) => handleItemChange("users", data)}
                multiSelect={true}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <WillShowNoResultsWarningContainer />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ padding: 5, paddingTop: 16 }}>
              <h6 className="mb-0" style={{ width: 80 }}>
                Samples
              </h6>
              <SampleRecords />
            </div>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
};

export default EventAlertRuleModal;
