import ActionType from "../actions/types";
import { CurrentUser } from "../util/types";

interface SetCurrentUserAction {
  type: ActionType.SET_CURRENT_USER;
  currentUser: CurrentUser;
}

interface UnsetCurrentUserAction {
  type: ActionType.UNSET_CURRENT_USER;
}

interface UpdateCurrentUserAvatarAction {
  type: ActionType.UPDATE_CURRENT_USER_AVATAR;
  avatar: string;
}

type CurrentUserAction =
  | SetCurrentUserAction
  | UnsetCurrentUserAction
  | UpdateCurrentUserAvatarAction;

export type CurrentUserState = CurrentUser | null;

export default function currentUser(
  state: CurrentUserState = null,
  action: CurrentUserAction
): CurrentUserState {
  switch (action.type) {
    case ActionType.SET_CURRENT_USER:
      return action.currentUser;
    case ActionType.UNSET_CURRENT_USER:
      return null;
    case ActionType.UPDATE_CURRENT_USER_AVATAR:
      return {
        ...(state as CurrentUser),
        avatar: action.avatar,
      };
    default:
      return state;
  }
}
