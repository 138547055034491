const SPECIFIC_ACTIONS: string[] = [];

export default (store: any) => (next: any) => (action: any) => {
  const returnedValue = next(action);
  if (
    process.env.REACT_APP_STORE_LOGS &&
    process.env.REACT_APP_STORE_LOGS === "enabled"
  ) {
    if (
      SPECIFIC_ACTIONS.length === 0 ||
      SPECIFIC_ACTIONS.includes(action.type)
    ) {
      console.group(action.type);
      console.log("The action:::", action);
      console.log("The new state:::", store.getState());
      console.groupEnd();
    }
  }
  return returnedValue;
};
