import ActionType from "../actions/types";
import { ModalState } from "../util/types";

interface FacesClusterModalState extends ModalState {
  faceId: string | null;
  facesClusterId: string | null;
  images: string[];
  userId: string | null;
  source: string | null;
}

interface ShowFacesClusterModalAction {
  type: ActionType.SHOW_FACES_CLUSTER_MODAL;
  faceId: string;
  facesClusterId: string;
  source: string;
  userId: string;
}

interface GetFacesClusterModalImagesAction {
  type: ActionType.GET_FACES_CLUSTER_MODAL_IMAGES;
  images: string[];
}

interface SetFacesClusterModalFacesClusterIDAction {
  type: ActionType.SET_FACES_CLUSTER_MODAL_FACES_CLUSTER_ID;
  facesClusterId: string;
}

interface DismissFacesClusterModalAction {
  type: ActionType.DISMISS_FACES_CLUSTER_MODAL;
}

type FacesClusterModalAction =
  | ShowFacesClusterModalAction
  | GetFacesClusterModalImagesAction
  | SetFacesClusterModalFacesClusterIDAction
  | DismissFacesClusterModalAction;

const initialState: FacesClusterModalState = {
  isOpen: false,
  faceId: null,
  facesClusterId: null,
  images: [],
  userId: null,
  source: null,
};

export default function facesClusterModal(
  state: FacesClusterModalState = { ...initialState },
  action: FacesClusterModalAction
): FacesClusterModalState {
  switch (action.type) {
    case ActionType.SHOW_FACES_CLUSTER_MODAL:
      return {
        ...state,
        isOpen: true,
        faceId: action.faceId,
        facesClusterId: action.facesClusterId,
        source: action.source,
        userId: action.userId,
      };
    case ActionType.GET_FACES_CLUSTER_MODAL_IMAGES:
      return {
        ...state,
        images: action.images,
      };
    case ActionType.SET_FACES_CLUSTER_MODAL_FACES_CLUSTER_ID:
      return {
        ...state,
        facesClusterId: action.facesClusterId,
      };
    case ActionType.DISMISS_FACES_CLUSTER_MODAL:
      return { ...initialState };
    default:
      return state;
  }
}
