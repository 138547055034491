import ActionType from "../actions/types";
import { AlertRules } from "../util/types";

type AlertRulesState = AlertRules;

interface ReceiveAlertRulesAction {
  type: ActionType.RECEIVE_ALERT_RULES;
  alertRules: AlertRules;
}

type AlertRulesAction = ReceiveAlertRulesAction;

export default function alertRules(
  state: AlertRulesState = [],
  action: AlertRulesAction
): AlertRulesState {
  switch (action.type) {
    case ActionType.RECEIVE_ALERT_RULES: {
      return action.alertRules;
    }
    default:
      return state;
  }
}
