import axios from "./index";
import { getPrivateEndpoint } from "./index";
import { getCookie } from "../util/cookies";
import { TOKEN_KEY } from "../util/constants";

export async function getUserById(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`users/${id}`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    throw e;
  }
}

/**
 * update a user
 * @param {userId} userId
 * @param {user} user
 */
export async function updateUser(userId: string, user: any) {
  try {
    const endpoint = getPrivateEndpoint(`users/${userId}`);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    delete user["entity_type"];
    delete user["id"];
    delete user["roles"];
    delete user["relations"];
    delete user["updated_fields"];
    const { data } = await axios.patch(endpoint, user, options);
    return data;
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data.request_status_message
    ) {
      throw e.response.data.request_status_message;
    } else throw e;
  }
}

export async function requestRfID(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`users/${id}/rfid-request`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateUserAvatar(userId: string, avatar: string) {
  try {
    const endpoint = getPrivateEndpoint(`users/${userId}/avatar`);
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCookie(TOKEN_KEY),
      },
    };
    const body = { fb64_data: avatar };
    const { data } = await axios.post(endpoint, body, options);
    return data;
  } catch (e) {
    console.log("ERROR FROM updateUserAvatar:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function getUserAccessLogs(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`users/${id}/activities`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    console.log("ERROR FROM getUserAccessLogs:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function getUserTasks() {
  try {
    const endpoint = getPrivateEndpoint(`users/me/tasks`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    console.log("ERROR FROM getUserAccessTasks:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}

export async function getUserEventLogs(id: string) {
  try {
    const endpoint = getPrivateEndpoint(`users/${id}/activities`);
    const { data } = await axios.get(endpoint);
    return data;
  } catch (e) {
    console.log("ERROR FROM getUserEventLogs:::", e, e.response, e.message);
    if (e.response && e.response.data && e.response.data.message) {
      throw e.response.data.message;
    } else throw e;
  }
}
