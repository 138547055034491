/**
 * eventFilterData
 * Data coming in order to fill the filters with available selectable options
 */

import ActionType from "../actions/types";
import {
  EventFilterData,
  EventGroups,
  EventLocations,
  EventProperties,
  EventTypes,
  EventUsers,
} from "../util/types";

type EventFilterDataState = EventFilterData;

interface ReceiveEventTypesAction {
  type: ActionType.RECEIVE_EVENT_TYPES;
  types: EventTypes;
}

interface ReceiveEventLocationsAction {
  type: ActionType.RECEIVE_EVENT_LOCATIONS;
  locations: EventLocations;
}

interface ReceiveEventPropertiesAction {
  type: ActionType.RECEIVE_EVENT_PROPERTIES;
  properties: EventProperties;
}

interface ReceiveEventGroupsAction {
  type: ActionType.RECEIVE_EVENT_GROUPS;
  groups: EventGroups;
}

interface ReceiveEventUsersAction {
  type: ActionType.RECEIVE_EVENT_USERS;
  users: EventUsers;
}

interface EventFilterReadyAction {
  type: ActionType.EVENT_FILTER_READY;
  ready: boolean;
}

type EventFilterDataAction =
  | ReceiveEventTypesAction
  | ReceiveEventLocationsAction
  | ReceiveEventPropertiesAction
  | ReceiveEventGroupsAction
  | ReceiveEventUsersAction
  | EventFilterReadyAction;

const initState: EventFilterDataState = {
  types: [],
  properties: [],
  locations: [],
  groups: [],
  users: [],
  ready: false,
};

export default function eventFilterData(
  state: EventFilterDataState = { ...initState },
  action: EventFilterDataAction
): EventFilterDataState {
  switch (action.type) {
    case ActionType.RECEIVE_EVENT_TYPES: {
      return {
        ...state,
        types: action.types,
      };
    }
    case ActionType.RECEIVE_EVENT_LOCATIONS: {
      return {
        ...state,
        locations: action.locations,
      };
    }
    case ActionType.RECEIVE_EVENT_PROPERTIES: {
      return {
        ...state,
        properties: action.properties,
      };
    }
    case ActionType.RECEIVE_EVENT_GROUPS: {
      return {
        ...state,
        groups: action.groups,
      };
    }
    case ActionType.RECEIVE_EVENT_USERS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case ActionType.EVENT_FILTER_READY: {
      return {
        ...state,
        ready: action.ready,
      };
    }
    default:
      return state;
  }
}
