import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import type {
  AutoCompleteValue,
  ID,
  KeyValue,
  ValueLabel,
} from "../../util/types";

interface CustomAutocompleteProps {
  label: string;
  placeholder: string;
  options: ValueLabel<ID>[];
  value: AutoCompleteValue;
  handleChange: (selected: AutoCompleteValue) => void;
  style?: KeyValue<any>;
  multiSelect: boolean;
  disabled?: boolean;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  label,
  placeholder,
  options,
  value,
  handleChange,
  style = {},
  multiSelect,
  disabled = false,
}) => {
  return multiSelect ? (
    <Autocomplete
      multiple
      id={placeholder}
      options={options}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      onChange={(_, selected) => handleChange(selected)}
      value={value as ValueLabel<string>[]}
      style={{ ...style }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
      disabled={disabled}
    />
  ) : (
    <Autocomplete
      id={label}
      options={options}
      getOptionLabel={(option) => option.label}
      style={{ ...style }}
      value={value as ValueLabel<string> | null}
      onChange={(_, selected) => handleChange(selected)}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      disabled={disabled}
    />
  );
};

CustomAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any.isRequired),
  multiSelect: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default CustomAutocomplete;
