import ActionType from "../actions/types";
import { Locks, LockStatus } from "../util/types";

type LocksState = Locks;

interface ReceiveLocksAction {
  type: ActionType.RECEIVE_LOCKS;
  locks: Locks;
}

interface ReceiveLockStatusAction {
  type: ActionType.RECEIVE_LOCK_STATUS;
  lockId: string;
  status: boolean;
  isAccessible: boolean;
  snapshot?: string;
}

interface MakeLockActionAction {
  type: ActionType.MAKE_LOCK_ACTION;
  lockId: string;
  status: boolean;
}

interface ResetLockStatus {
  type: ActionType.RESET_LOCK_STATUS;
  lockId: string;
}

interface ClearLocksAction {
  type: ActionType.CLEAR_LOCKS;
}

type LocksAction =
  | ReceiveLocksAction
  | ReceiveLockStatusAction
  | MakeLockActionAction
  | ResetLockStatus
  | ClearLocksAction;

export default function locks(
  state: LocksState = {},
  action: LocksAction
): LocksState {
  switch (action.type) {
    case ActionType.RECEIVE_LOCKS:
      return action.locks;
    case ActionType.RECEIVE_LOCK_STATUS:
      return {
        ...state,
        [action.lockId]: {
          ...state[action.lockId],
          currentStatus: action.isAccessible
            ? action.status
              ? LockStatus.OPENED
              : LockStatus.CLOSED
            : LockStatus.NOT_ACCESSIBLE,
          isAccessible: action.isAccessible,
          statusTimestamp: new Date(),
          snapshot: action.snapshot,
        },
      };
    case ActionType.MAKE_LOCK_ACTION:
      return {
        ...state,
        [action.lockId]: {
          ...state[action.lockId],
          currentStatus: action.status ? LockStatus.OPENED : LockStatus.CLOSED,
        },
      };
    case ActionType.RESET_LOCK_STATUS:
      return {
        ...state,
        [action.lockId]: {
          ...state[action.lockId],
          currentStatus: LockStatus.NOT_REQUESTED,
          statusTimestamp: null,
          isAccessible: undefined,
          snapshot: undefined,
        },
      };
    case ActionType.CLEAR_LOCKS:
      return {};
    default:
      return state;
  }
}
