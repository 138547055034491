import React from "react";
import { useDispatch } from "react-redux";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { dismissVideoModal } from "../../../actions/videoModal";
import CustomModal from "../CustomModal";
import { MODAL_WIDTH } from "../../../util/constants";
import useAppSelector from "../../../hooks/useAppSelector";

const VideoModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.videoModal.isOpen);
  const title = useAppSelector((state) => state.videoModal.name);
  const url = useAppSelector((state) => state.videoModal.url);
  const windowWidth = useWindowWidth(isOpen);

  const handleCancelClick = () => {
    dispatch(dismissVideoModal());
  };

  const width = (windowWidth < MODAL_WIDTH ? windowWidth : MODAL_WIDTH) - 10;
  const height = (width * 600) / 800;

  return (
    <CustomModal
      isOpen={isOpen}
      title={title}
      showFooter
      confirmLabel="Close"
      handleConfirm={handleCancelClick}
    >
      <video
        width={width - 35}
        height={height}
        src={url as string}
        autoPlay
        controls
      />
    </CustomModal>
  );
};

export default VideoModal;
