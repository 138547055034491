import { toast } from "react-toastify";
import { addNewUserToGroup } from "../api/groups";
import { AppThunkAction, ID } from "../util/types";
import { handleGetGroup } from "./groups";
import ActionType from "./types";

export const showAddUserToGroupModal = ({
  groupId,
  email,
}: {
  groupId: ID;
  email: string;
}) => ({
  type: ActionType.SHOW_ADD_USER_TO_GROUP_MODAL,
  groupId,
  email,
});

export const dismissAddUserToGroupModal = () => ({
  type: ActionType.DISMISS_ADD_USER_TO_GROUP_MODAL,
});

export const handleAddUserToGroup =
  ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const { groupId, email } = getState().addUserToGroupModal;
    if (!(groupId && email)) return;
    try {
      const { success } = await addNewUserToGroup(groupId, {
        email,
        first_name: firstName,
        last_name: lastName,
      });
      toast.success(success);
    } catch (e) {
      console.log(e);
      if (e && e.message && e.message === "Network Error") {
        // cors error but worked...
        dispatch(handleGetGroup(groupId));
        dispatch(dismissAddUserToGroupModal());
        toast.success("Added successfully");
      } else {
        toast.error(typeof e === "string" ? e : e.message);
      }
    }
  };
