import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";

interface CustomCheckboxProps {
  value?: boolean;
  handleChange: (e: any) => void;
  label: string;
  customClass?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  value = false,
  handleChange,
  label,
  customClass = "",
}) => {
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChange(e.target.checked);
  };

  return (
    <FormGroup check className={customClass}>
      <Label check>
        <Input
          type="checkbox"
          className="input-section-checkbox"
          checked={value}
          onChange={handleInputChange}
        />
        &nbsp;{label}
      </Label>
    </FormGroup>
  );
};

CustomCheckbox.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};

export default CustomCheckbox;
