import { toast } from "react-toastify";
import {
  assignFacesClusterToUser,
  getFacesCluster,
  unassignFacesCluster,
} from "../api/events";
import { FACES_CLUSTER_MODAL_SOURCES } from "../util/constants";
import { AppThunkAction, ID } from "../util/types";
import { handleReceiveEventLogs } from "./eventLogs";
import ActionType from "./types";
import { removeUserFaceClustersItem } from "./user";

export const showFacesClusterModal = ({
  faceId,
  facesClusterId,
  source,
  userId,
}: {
  faceId?: ID;
  facesClusterId: ID;
  source: string;
  userId?: ID;
}) => ({
  type: ActionType.SHOW_FACES_CLUSTER_MODAL,
  faceId,
  facesClusterId,
  source,
  userId,
});

export const dismissFacesClusterModal = () => ({
  type: ActionType.DISMISS_FACES_CLUSTER_MODAL,
});

const getFacesClusterModalImages = (images: string[]) => ({
  type: ActionType.GET_FACES_CLUSTER_MODAL_IMAGES,
  images,
});

export const handleGetFacesClusterModalImages =
  (): AppThunkAction => async (dispatch, getState) => {
    try {
      const { facesClusterId } = getState().facesClusterModal;
      const { faceCluster: facesPerson } = await getFacesCluster({
        id: facesClusterId as string,
        params: { face_rows_limit: 32 },
      });
      if (!facesPerson) throw Error("Couldn't get the cluster details");
      const images = Object.values(
        facesPerson.snapshots as { url: string }[]
      ).map(({ url }) => url);
      dispatch(getFacesClusterModalImages(images));
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
  };

export const handleAssignFacesClusterToUser =
  (newUserId: ID): AppThunkAction =>
  async (dispatch, getState) => {
    try {
      const { facesClusterId, source } = getState().facesClusterModal;
      const { success } = await assignFacesClusterToUser(
        facesClusterId as string
      )({
        userId: newUserId,
      });
      toast.success(success);
      dispatch(handleReceiveEventLogs());
      if (source === FACES_CLUSTER_MODAL_SOURCES.userDashboard) {
        dispatch(removeUserFaceClustersItem(facesClusterId as string));
      }
      dispatch(dismissFacesClusterModal());
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
  };

export const handleUnassignFacesCluster =
  (): AppThunkAction => async (dispatch, getState) => {
    try {
      const { facesClusterId, source, userId } = getState().facesClusterModal;
      const { success } = await unassignFacesCluster(facesClusterId as string)({
        userId,
      });
      toast.success(success);
      dispatch(handleReceiveEventLogs());
      if (source === FACES_CLUSTER_MODAL_SOURCES.userDashboard) {
        dispatch(removeUserFaceClustersItem(facesClusterId as string));
      }
      dispatch(dismissFacesClusterModal());
    } catch (e) {
      toast.error(typeof e === "string" ? e : e.message);
      console.log(e);
    }
  };
